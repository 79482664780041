// components

import ControlBtn from './commons/header/ControlBtn'
import Layout, { AutoLayout } from './commons/header/Layout'
import Logo from './commons/header/Logo'
import Wrapper from './commons/header/Wrapper'

const Header = ({
  onSend,
  onset,
  onNavToggle,
  onOptionToggle,
  onLearningToggle,
  isSpeech,
}: Props) => {
  return (
    <Wrapper>
      <Layout>
        <AutoLayout>
          <Logo />
          <div>
            {/* <Speech1 data={yyy}/>
            <Btn onClick={handleAudio}>Audio</Btn> */}
            <ControlBtn message="Speech" onClick={onset} />
            <ControlBtn message="Contents" onClick={onNavToggle} />
            <ControlBtn message="Setting" onClick={onOptionToggle} />
            <ControlBtn message="Highlights" onClick={onLearningToggle} />
            {/* {isSpeech !== "Android" && <CloseIcon2 />} */}
          </div>
        </AutoLayout>
      </Layout>
    </Wrapper>
  )
}

interface Props {
  onSend: any
  onset: (value?: boolean) => void
  onNavToggle: (value?: boolean) => void
  onOptionToggle: (value?: boolean) => void
  onLearningToggle: (value?: boolean) => void
  isSpeech?: any
  // stop : (value?: boolean) => void;
}

export default Header
