import { Buffer } from 'buffer'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { basicAuthOfEnableX, getUserInfo } from '../../helpers/authHelper'
import { generateTokenForRoom } from '../../helpers/backendHelpers/liveClass'
import { BASE_URL, IMAGE_URL } from '../../helpers/urlHelper'

const LiveClassRoom = (props) => {
  const { roomUrl } = useParams()
  const [token, setToken] = useState('')
  const [hostHash, setHostHash] = useState('')
  const userInfo = getUserInfo()
  const [searchParams, setSearchParams] = useSearchParams()
  const uTypeFromParams = searchParams.get('uType')
  const uIdFromParams = searchParams.get('uId')
  const userName = searchParams.get('uName')
  const uType = searchParams.get('uType')
  // const [uType, setuType] = useState('1')
  const [uId, setuId] = useState('')
  const [temp, setTemp] = useState(true)
  const [name, setName] = useState('')

  const generateToken = async (id) => {
    let data = {
      name: 'John Doe',
      role: 'participant',
      user_ref: 'XXXXX',
    }
    let res = await generateTokenForRoom(id, data)
    if (res.status) {
      setToken(res.data.token)
    }
  }

  useEffect(() => {
    generateToken(roomUrl)
  }, [roomUrl])

  useEffect(() => {
    setuId(uIdFromParams)
    setTemp(!temp)
  }, [])

  useEffect(() => {
    const ROOM_ID = roomUrl
    const APP_ID = basicAuthOfEnableX.auth.username
    const encodedHash = Buffer.from(`${ROOM_ID}-${APP_ID}`).toString('base64')
    setHostHash(encodedHash)
    const name = userInfo?.fullName ? userInfo?.fullName : 'name'
    setName(name)
  }, [userInfo])

  return (
    <>
      {roomUrl &&
        roomUrl != undefined &&
        (uType && (uType === 'Teacher' || uType === 'Admin') ? (
          <iframe
            title="iframe 1"
            style={{ width: '100%', height: '100vh' }}
            allow="camera; microphone; fullscreen; speaker; display-capture"
            target="_top"
            src={`https://katon.yourvideo.live/host/${hostHash}?my_virtual_img=${IMAGE_URL}/kt_trainingProgram/tp_programImage/1678815948748.jpg&landing=${
              uType === 'Admin' ? 'yes' : 'no'
            }&${
              uType === 'Admin' ? '' : 'name=' + userName
            }&whiteboard=yes&exit_url=${BASE_URL}/live-class-end/${roomUrl}`}
          ></iframe>
        ) : (
          <iframe
            title="iframe2"
            target="_top"
            style={{ width: '100%', height: '100vh' }}
            allow="camera; microphone; fullscreen=true; speaker; display-capture"
            src={`https://katon.yourvideo.live/${roomUrl}?video=yes&audio=yes&${
              !uType ? '' : 'landing=no&name=' + userName
            }&exit_url=${BASE_URL}/live-class-end/${roomUrl}`}
          ></iframe>
        ))}
    </>
  )
}

export default LiveClassRoom
