import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAssignmentResult } from '../../helpers/backendHelpers/assignmentResult'

const AssignmentResult = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [assignmentResult, setAssignmentResult] = useState([])
  const [queAns, setQueAns] = useState([])
  const [error, setError] = useState('')

  const getAssignmentResultById = async (id) => {
    try {
      setIsLoading(true)
      let response = await getAssignmentResult(id)
      let { assignmentResult } = response.data
      setAssignmentResult(assignmentResult)
      let convertedQueAns = JSON.parse(assignmentResult?.ar_answerKeys)
      setQueAns(convertedQueAns)
    } catch (error) {
      console.log(error)
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAssignmentResultById(id)
  }, [])

  return (
    <>
      <section className="practice py-5">
        <div className="container">
          <div className="row books-section">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  <p className="h5">
                    {assignmentResult?.ar_assignment?.asn_title}
                  </p>
                </div>
                <div className="books-sidebar-widget">
                  <div className="books-wrapper-header">
                    <p className="h6 mb-3">Score : 20</p>
                    <p className="h6 mb-3">Total Questions : 2</p>
                    <p className="h6 mb-3">Unanswered Questions : 0</p>
                    <p className="h6 mb-3">Time Taken : 00:00:16</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mb-3">
              {queAns &&
                queAns.map((data, i) => {
                  return (
                    <div className="pastPaperBox bg-white mb-3" key={i}>
                      <div className="h6 font_medium mb-2">
                        {`${i + 1}. ${data?.question}`}
                      </div>
                      {data.option.map((que, i) => {
                        let optionIndex = ''
                        if (i === 0) {
                          optionIndex = 'A'
                        } else if (i === 1) {
                          optionIndex = 'B'
                        } else if (i === 2) {
                          optionIndex = 'C'
                        } else if (i === 3) {
                          optionIndex = 'D'
                        } else if (i === 4) {
                          optionIndex = 'E'
                        }
                        let correctAnswer = data?.answer === que
                        let IncorrectAnswer =
                          data?.selectedOption === que &&
                          data?.selectedOption != data?.answer
                        return (
                          <>
                            <div
                              key={i}
                              className={`${que === '' ? 'd-none' : 'd-flex'} ${
                                correctAnswer && 'correctAnsBg'
                              } ${IncorrectAnswer && 'inCorrectAnsBg'}`}
                            >
                              <span className="rounded-circle border">
                                {optionIndex}
                              </span>
                              <span className="ms-2 pt-2">{que}</span>
                            </div>
                          </>
                        )
                      })}
                      <hr className="contact-hr" />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AssignmentResult
