import Testimonial from '../../components/Testimonial'
import AboutSection from './components/AboutSection'
import WhyChooseUs from './components/WhyChooseUs'

const About = () => {
  return (
    <>
      <AboutSection />
      <WhyChooseUs classes={'bg-section-light'} />
      {/* <Team classes="bg-white" /> */}
      <Testimonial />
    </>
  )
}

export default About
