import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'swiper/css'
import 'swiper/css/pagination'
import './App.scss'
import Header from './components/Header'
import Layout from './components/Layout'
import AuthContextProvider from './contexts/AuthContext'
import { authProtectedRoutes, publicRoutes } from './routes/index'
import Authmiddleware from './routes/routes'

const App = () => {
  const [path, setPath] = useState('1')
  function ScrollToTop() {
    const { pathname } = useLocation()
    setPath(pathname)
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
    return null
  }

  // const [isUser, setIsUser] = useState(true);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthContextProvider>
        {path.match('/live-class-room') ||
        path.match('/live-class-end') ||
        path.match('/mcqTest') ||
        path.match('/selfAssessmentTest') ||
        path.match('/trainingExam') ||
        path.match('/not-found') ||
        path.match('/epub') ? (
          ''
        ) : (
          <Header />
        )}
        {authProtectedRoutes.map((route, idx) => (
          <Routes key={idx}>
            <Route
              path={route.path}
              element={
                <Authmiddleware
                  isAuthProtected={true}
                  layout={Layout}
                  isLayout={route.isLayout}
                  component={route.component}
                  {...route}
                />
              }
            />
          </Routes>
        ))}
        {publicRoutes.map((route, idx) => (
          <Routes key={idx}>
            <Route
              path={route.path}
              element={
                <Authmiddleware
                  isAuthProtected={false}
                  layout={Layout}
                  isLayout={route.isLayout}
                  component={route.component}
                  {...route}
                />
              }
            />
          </Routes>
        ))}
      </AuthContextProvider>
    </BrowserRouter>
  )
}

export default App

// just commit 