import Footer from '../../components/Footer'
import Banner from './components/Banner'
import Certifications from './components/Certifications'
import Feature from './components/Feature'
import SlidCard from './components/SlidCard'
import TrainingModule from './components/TrainingModule'

const Home = () => {
  return (
    <>
      <Banner />
      <Certifications />
      {/* <ProductSneakPeek /> */}
      {/* <About /> */}
      <Feature />
      {/* <Blog /> */}
      {/* <Team /> */}
      {/* <TrainingProgramHome /> */}
      <TrainingModule />
      <SlidCard />
      {/* <StudentExpe /> */}
      {/* <StudentExperience /> */}
      {/* <WhoJoin /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}
      {/* <ClientSlider /> */}
      <Footer />
    </>
  )
}

export default Home
