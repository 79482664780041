import React, { useEffect, useState } from 'react'
import {
  getAllAssignment,
  // getAssignmentResultByStudentAPI,
} from '../../helpers/backendHelpers/assignment'
import './Practice.css'
// import { BsCalendarWeek } from 'react-icons/bs'
// import moment from 'moment'
// import { IMAGE_URL } from '../../helpers/urlHelper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getFullUserInfo, getUserInfo } from '../../helpers/authHelper'
import swal from 'sweetalert'
import { getAllAssignmentResult } from '../../helpers/backendHelpers/assignmentResult'
import { getAuthToken } from '../../helpers/authHelper'
// import default_icon from '../../assets/images/default_avatar.png'
import SubmitLoader from 'components/Common/SubmitLoader'
import AnswerListModal from 'components/Common/AnswerListModal'

const Practice = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [assignment, setAssignment] = useState([])
  const [assignmentResult, setAssignmentResult] = useState([])
  const [noRecords, setNoRecords] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const userInfo = getUserInfo()
  const [answerListModal, setAnswerListModal] = useState(false)
  const [assignmentId, setAssignmentId] = useState('')
  const [subject, setSubject] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')

  const getAssignment = async (id, level, userType) => {
    try {
      setIsLoading(true)
      let response = await getAllAssignment(id, level, userType)
      let { assignment } = response.data

      let subjectList = assignment.map((item) => item.asn_subCategory)
      subjectList = [...new Set(subjectList)]

      setAssignment(assignment)
      setSubject(subjectList)
      setIsLoading(false)

      if (assignment.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const getAssignmentResultByStudent = async () => {
    try {
      setIsLoading(true)
      let response = await getAllAssignmentResult()
      let { assignmentResult } = response.data
      setAssignmentResult(assignmentResult)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const userInfo = getUserInfo()
    if (userInfo?.userType === 'Student') {
      const studentInfo = getFullUserInfo()
      getAssignment(userInfo?.id, studentInfo?.st_level, 'Student')
    } else if (userInfo?.userType === 'Teacher') {
      const teacherInfo = getFullUserInfo()
      getAssignment(userInfo?.id, teacherInfo?.tc_level, 'Teacher')
    } else {
      getAssignment(userInfo?.id)
    }

    if (userInfo) {
      getAssignmentResultByStudent()
    }
  }, [])

  const LoginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const handleStartAssignmentClick = async (asn_id) => {
    const isLoggedIn = getAuthToken() ? true : false

    if (!isLoggedIn) {
      LoginAlert()
    } else {
      window.open(
        `/mcqTest/${userInfo?.id}/${asn_id}`,
        'Popup',
        'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, maximize=1, fullscreen=1',
      )
    }
  }

  const toggleAnswerListModal = () => {
    setAnswerListModal(!answerListModal)
  }

  let displayAssignment = assignment
  if (selectedSubject && selectedSubject.length > 0) {
    displayAssignment = displayAssignment.filter(
      (assignment) => assignment.asn_subCategory === selectedSubject,
    )
  }
  return (
    <>
      <AnswerListModal
        modalTitle="Student Result"
        asn_id={assignmentId}
        isOpen={answerListModal}
        toggle={toggleAnswerListModal}
        type="pdf"
      />
      <div style={{ minHeight: '300px' }}>
        {isLoading ? (
          <div
            style={
              isLoading
                ? { position: 'relative', opacity: '0.8', minHeight: '300px' }
                : {}
            }
          >
            <SubmitLoader />
          </div>
        ) : (
          <>
            <section className="practice py-5">
              <div className="container">
                <div className="row books-section">
                  <div className="books-sidebar col-lg-3">
                    <div className="books-sidebar-inner">
                      <div className="books-sidebar-top books-sidebar-widget">
                        <h5 className="subtitle">Subjects</h5>
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          onClick={() => setSelectedSubject('')}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="books-sidebar-widget">
                        <div className="books-wrapper-header">
                          {subject?.map((item) => (
                            <button
                              className={`btn mb-2 col-md-8 ${
                                item === selectedSubject
                                  ? 'btnAssReview'
                                  : 'btnAssReviewE'
                              }`}
                              onClick={() => setSelectedSubject(item)}
                            >
                              {' '}
                              <b>{item}</b>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div>
                      {noRecords && (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: '300px' }}
                        >
                          <h1>No system activity found.</h1>
                        </div>
                      )}
                      <div className="row">
                        {displayAssignment &&
                          displayAssignment.map((data, i) => {
                            return (
                              <>
                                <div className="col-md-6 mb-3" key={i}>
                                  <div className="pastPaperBox bg-white p-4 rounded-4">
                                    <h5>{data.asn_title}</h5>
                                    &nbsp;
                                    {/* <p>{data?.asn_desc}</p> */}
                                    <div className="teacher_details">
                                      <div className="d-flex mb-2">
                                        <div className="name">
                                          <b className="h7">
                                            {data.asn_teacher.tc_fullName}
                                          </b>
                                        </div>
                                        <span className="ms-auto d-flex align-items-center">
                                          {data?.asn_subCategory}
                                        </span>
                                      </div>
                                      {userInfo?.userType === 'Student' && (
                                        <ul className="m-0 p-0 mb-3">
                                          <li className="d-flex">
                                            <span>Total marks: </span>
                                            <span className=" ms-3 ">
                                              {data.asn_totalMarks}
                                            </span>
                                            <div className="d-flex ms-auto">
                                              <span className="">
                                                Duration:
                                              </span>
                                              <span className="ms-3">
                                                {data.asn_duration}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                    {userInfo?.userType === 'Teacher' && (
                                      <ul className="m-0 p-0 mb-3">
                                        <li className="d-flex">
                                          <i className="fa-solid fa-user-plus mt-1"></i>
                                          <span style={{ marginLeft: '4%' }}>
                                            Number of Students
                                          </span>
                                          <div className="d-flex ms-auto">
                                            <span className="ms-3">
                                              {data?.asn_solveCount}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    )}
                                    <div className="d-block">
                                      <div className="row">
                                        <div className="col-md-5"></div>
                                        <div className="d-flex col-md-7">
                                          {userInfo?.userType === 'Student' &&
                                          data.asn_isCheckResult ? (
                                            <Link
                                              to={`/practice/practiceResult/${data?.asn_id}`}
                                              className="checkResultBtn"
                                              rel="noopener noreferrer"
                                              style={{
                                                width: '100%',
                                                textAlign: 'center',
                                              }}
                                            >
                                              Check Result
                                            </Link>
                                          ) : (
                                            <Link
                                              style={{
                                                width: '100%',
                                                textAlign: 'center',
                                              }}
                                              className={`${
                                                userInfo?.userType ===
                                                  'Teacher' ||
                                                userInfo?.userType ===
                                                  'Freelance'
                                                  ? 'checkResultBtn'
                                                  : 'cmn--btn'
                                              }`}
                                              rel="noopener noreferrer"
                                              onClick={() => {
                                                if (
                                                  !userInfo?.userType ||
                                                  userInfo?.userType ==
                                                    'Student'
                                                ) {
                                                  handleStartAssignmentClick(
                                                    data.asn_id,
                                                  )
                                                } else if (
                                                  userInfo?.userType ==
                                                  'Teacher'
                                                ) {
                                                  toggleAnswerListModal()
                                                  setAssignmentId(data.asn_id)
                                                }
                                              }}
                                            >
                                              {(userInfo?.userType ===
                                                'Teacher' ||
                                                userInfo?.userType ===
                                                  'Freelance') &&
                                                `${data?.asn_solveCount} Attempted`}
                                              {userInfo?.userType ===
                                                'Student' &&
                                                data?.asn_solveCount < 0 &&
                                                'Check Result'}
                                              {(userInfo?.userType ===
                                                'Student' ||
                                                userInfo?.userType ===
                                                  'Premium') &&
                                                'Try'}
                                              {!userInfo?.userType && 'Try'}
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default Practice
