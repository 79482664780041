import { useSearchParams } from 'react-router-dom'
import { IMAGE_URL } from '../../helpers/urlHelper'
import ReaderWrapper from './container/Reader.tsx'

const EPub = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let ePubUrl = searchParams.get('ePub')
  const EPUB_URL = `${IMAGE_URL}/${ePubUrl}`

  return <ReaderWrapper url={EPUB_URL} isSpeech="web" />
}

export default EPub
