import learning from '../../assets/images/data/learning'
import SectionHeader from '../../components/SectionHeader'
import './Learning.scss'
import LearningCard from './LearningCard'

const Learning = () => {
  return (
    <section className="learning-section py-5">
      <div className="container">
        <SectionHeader
          className="mb-0"
          title="360 Degree Learning Hub"
          subtitle=""
          text="A learning hub for students and professionals/workers that leverages live online teachers, test books, journals, learning curriculum, solved past exam questions, media (recorded presentations), and technology to deliver superior academic and technical training. We conduct comprehensive online assessments for all levels of students and professionals through live interactive quizzes and presentations."
        />
      </div>
      {learning &&
        learning.map((data, i) => <LearningCard index={i} {...data} key={i} />)}
    </section>
  )
}

export default Learning
