import FormModal from 'components/Common/FormModal'
import SubmitLoader from 'components/Common/SubmitLoader'
import { getFullUserInfo, getUserInfo } from 'helpers/authHelper'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import bookImage from '../../assets/images/finalBook.jpg'
import DashboardSidebar from '../../components/DashboardSidebar'
import PaginatedItems from '../../components/PaginatedItems'
import { AuthContext } from '../../contexts/AuthContext'
import { removeAuthInfo } from '../../helpers/authHelper'
import {
  logOutParent,
  logOutPublisher,
  logOutUser,
  logOutfTeacher,
} from '../../helpers/backendHelper'
import {
  getLibraryBooksStudent,
  getLibraryBooksTeacher,
} from '../../helpers/backendHelpers/book'
import LibraryBookDetail from './LibraryBookDetail'
import LibraryItems from './LibraryItems'

const Library = () => {
  const navigate = useNavigate()
  
  const [books, setBooks] = useState([])
  const [error, setError] = useState('')
  const { setLogout } = useContext(AuthContext)
  const [showDetails, setShowDetails] = useState(false)
  const [bookId, setBookId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const [formModal, setFormModal] = useState(false)
  const userInfo = getUserInfo()
  const fullUserInfo = getFullUserInfo()

  useEffect(() => {
    getMyLibraryBooks()
  }, [])

  useEffect(() => {
    if (
      (userInfo?.userType === 'Student' && !fullUserInfo.isFirstTimeLogin) ||
      (userInfo?.userType === 'Teacher' && !fullUserInfo.isFirstTimeLogin)
    ) {
      setFormModal(true)
    }
  }, [fullUserInfo])

  const getMyLibraryBooks = async () => {
    try {
      setIsLoading(true)
      let response = {}
      if (
        userInfo?.userType === 'Student' ||
        userInfo?.userType === 'Premium'
      ) {
        response = await getLibraryBooksStudent()
      } else if (
        userInfo?.userType === 'Teacher' ||
        userInfo?.userType === 'Freelance'
      ) {
        response = await getLibraryBooksTeacher()
      }
      let { bookList } = response.data
      console.log('bookList12', bookList)
      setBooks(bookList)
      setIsLoading(false)
      if (bookList.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  const toggleDetails = (id = '') => {
    setBookId(id)
    setShowDetails(!showDetails)
  }

  const toggleFormModal = () => {
    setFormModal(false)
  }

  const closeToggleFormModal = () => {
    setFormModal(false)
    if (userInfo === 'Student') {
      logOutUser()
    } else if (userInfo === 'Teacher') {
      logOutfTeacher()
    } else if (userInfo === 'Parent') {
      logOutParent()
    } else if (userInfo === 'Publisher') {
      logOutPublisher()
    }
    removeAuthInfo()
    setLogout()
    navigate('/auth/signin')
  }

  return (
    <>
      <ToastContainer position="top-right" />
      {showDetails && (
        <LibraryBookDetail bookIdProps={bookId} toggleDetails={toggleDetails} />
      )}
      <FormModal
        toggle={toggleFormModal}
        closeToggleFormModal={closeToggleFormModal}
        modalTitle={'Welcome to KATON'}
        isOpen={formModal}
        type="pdf"
      />
      <div className={`py-5 ${showDetails ? 'd-none' : 'd-block'}`}>
        <div className="container">
          <div className="d-flex flex-wrap">
            <DashboardSidebar />
            <div className="dashboard-article">
              <div className="card cmn--card">
                <div className="card-header py-3">
                  <h5 className="card-title">My Library</h5>
                </div>
                <div style={{ minHeight: '300px' }}>
                  {isLoading ? (
                    <div
                      style={
                        isLoading
                          ? {
                              position: 'relative',
                              opacity: '0.8',
                              minHeight: '300px',
                            }
                          : {}
                      }
                    >
                      <SubmitLoader />
                    </div>
                  ) : (
                    <>
                      {noRecords && (
                        <div
                          className="mt-2 library__item-img"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'white',
                          }}
                        >
                          <img src={bookImage} alt="Book Library" />
                          No system activity found.
                        </div>
                      )}
                      <div className="card-body">
                        <div className="py-3 px-2">
                          <div className="row g-3 g-md-4 g-lg-3 g-xl-4">
                            {books?.length ? (
                              <PaginatedItems
                                data={books}
                                toggleDetails={toggleDetails}
                                itemsPerPage={8}
                                ItemName={LibraryItems}
                                getMyLibraryBooks={getMyLibraryBooks}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Library
