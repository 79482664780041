import styled from 'styled-components'
// lib
import * as styles from 'lib/styles/styles'
import palette from 'lib/styles/palette'

const ControlBtn = ({ message, onClick }: Props) => {
  return <Btn onClick={() => onClick()}>{message}</Btn>;
}

const Btn = styled.button`
  height: 100% !important;
  border:none !important;
  padding: 0 16px !important;
  cursor: pointer !important;
  display: flex !important;
  background:none !important; 
  align-items: center !important;
  justify-content: center !important;
  transition: .1s ${styles.transition} !important;
  outline: none !important;

  &:focus, &:hover {
    color: ${palette.blue3} !important;
  }

  // &:first-child {
  //   margin-left: 8px;
  // }
`;

interface Props {
  message: string;
  onClick: (value?: boolean) => void;
}

export default ControlBtn