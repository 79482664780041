import './Privacy.scss'
const Terms = () => {
  return (
    <section className="terms-section py-5">
      <div className="container">
        <ol>
          <li>
            <h6>Welcome to LMS!</h6>
            <ol>
              <li>
                <h6 className="subtitle">Introduction:</h6>
                <p>
                  LMS GH provides its services (described below) to you through
                  its website located at https://lmsgh.net/ (the “Site”) and
                  through its mobile applications and related services
                  (collectively, such services, including any new features and
                  applications, and the Site, the “Service(s)”), subject to the
                  following Terms of Service (the “Terms of Service”).PLEASE
                  READ THESE TERMS OF SERVICE CAREFULLY, AS THEY GOVERN YOUR USE
                  OF THE SITE AND SERVICES, PARTICULARLY SECTION 10 (BINDING
                  ARBITRATION; CLASS ACTION WAIVER), WHICH AFFECTS YOUR RIGHTS
                  IN THE EVENT OF A DISPUTE BETWEEN US.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Modifications to Terms of Service:</h6>
                <p>
                  At our sole discretion, we reserve the right to change or
                  modify portions of these Terms of Service at any time. If we
                  do this, depending on the nature of the change, we will post
                  the changes on this page and indicate at the top of this page
                  the date these terms were last revised and/or notify you,
                  either through the Services' user interface, in an email
                  notification or through other reasonable means and as required
                  by applicable law. Any such changes will become effective no
                  earlier than fourteen (14) days after they are posted, except
                  that changes addressing new functions of the Services or
                  changes made for legal reasons will be effective immediately.
                  Your continued use of the Service after the date any such
                  changes become effective constitutes your acceptance of the
                  new Terms of Service. In addition, when using certain
                  Services, you will be subject to any additional terms
                  applicable to such Services that may be posted on the Service
                  from time to time.
                </p>
              </li>
              <li>
                <h6>Privacy:</h6>
                <p>
                  At LMS, we respect the privacy of our users. For details
                  please see our Privacy Policy By using the Service, you
                  consent to our collection and use of personal data as outlined
                  therein.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 className="subtitle">Access and Use of the Service</h6>
            <ol>
              <li>
                <h6 className="subtitle">Use Description:</h6>
                <p>
                  The LMS platform, and any content viewed through our service,
                  is solely for your personal and non-commercial use. With your
                  LMS purchase/subscription we grant you a limited,
                  non-exclusive, non-transferable, access to the LMS content and
                  view your books/course(s) through the service on a
                  streaming-only basis for that purpose. Except for the
                  foregoing limited license, no right, title or interest shall
                  be transferred to you. You agree not to use the service for
                  public performances. LMS may revoke your license/access at any
                  time in its sole discretion. Upon such revocation, you must
                  promptly destroy all content downloaded or otherwise obtained
                  through the service, as well as copies of such materials,
                  whether made in accordance with these Terms of Service or
                  otherwise.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Your Registration Obligations:</h6>
                <p>
                  You may be required to register with LMS in order to access
                  and use certain features of the Service. If you choose to
                  register for the Service, you agree to provide and maintain
                  true, accurate, current and complete information about
                  yourself as prompted by the Service’s registration form.
                  Registration data and certain other information about you are
                  governed by ours <a href="/privacy-policy">Privacy Policy </a>
                  .
                </p>
              </li>
              <li>
                <h6 className="subtitle">
                  Member Account, Password and Security:
                </h6>
                <p>
                  You may never use another's account, and you may not provide
                  another person with the username and password to access your
                  account. You should maintain control over all of the devices
                  that are used to access the Service. If you fail to maintain
                  control of a device, other users may access the Service
                  through your account and may be able to access certain of your
                  account information. You are fully responsible for any and all
                  activities that occur under your password or account, and it
                  is your responsibility to ensure that your password remains
                  confidential and secure. You agree to (a) immediately notify
                  LMS of any unauthorized use of your password or account or any
                  other breach of security, and (b) ensure that you exit from
                  your account at the end of each session when accessing the
                  Service. LMS will not be liable for any loss or damage arising
                  from your failure to comply with this Section.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Modifications to Service:</h6>
                <p>
                  LMS reserves the right to modify or discontinue, temporarily
                  or permanently, the Service (or any part thereof) with or
                  without notice. You agree that LMS will not be liable to you
                  or to any third party for any modification, suspension or
                  discontinuance of the Service. We have no obligation to retain
                  any of Your Account or Submitted Content for any period of
                  time beyond what may be required by applicable law.
                </p>
              </li>
              <li>
                <h6 className="subtitle">
                  Modifications to Service:Mobile Services:
                </h6>
                <p>
                  The Service may include certain services that are available
                  via a mobile device, including (i) the ability to upload
                  content to the Service via a mobile device, (ii) the ability
                  to browse the Service and the Site from a mobile device and
                  (iii) the ability to access certain features through an
                  application downloaded and installed on a mobile device
                  (collectively, the “Mobile Services”). To the extent you
                  access the Service through a mobile device, your wireless
                  service carrier’s standard charges, data rates and other fees
                  may apply. In addition, downloading, installing, or using
                  certain Mobile Services may be prohibited or restricted by
                  your carrier, and not all Mobile Services may work with all
                  carriers or devices. In using the Mobile Services, you may
                  provide your telephone number. By providing your telephone
                  number, you consent to receive calls and/or SMS, MMS, or text
                  messages at that number. We may share your phone numbers with
                  our affiliates or with our service providers (such as customer
                  support, billing or collections companies, and text message
                  service providers) who we have contracted with to assist us in
                  pursuing our rights or providing our Services under these
                  Terms of Service, our policies, applicable law, or any other
                  agreement we may have with you. You agree these parties may
                  also contact you using autodialed or prerecorded calls and
                  text messages, as authorized by us to carry out the purposes
                  we have identified above, and not for their own purposes. In
                  the event you change or deactivate your mobile telephone
                  number, you agree to promptly update your LMS account
                  information to ensure that your messages are not sent to the
                  person that acquires your old number.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Money Back Guarantee:</h6>
                <p>
                  Unless otherwise noted during your purchase/subscription of
                  the Service you choose, or as set forth in the refund policy
                  (see Section 3.3 below), should you become dissatisfied with
                  the Service within the first 15 days after purchase, LMS will
                  refund the full amount of your purchase and subsequently
                  terminate your access/subscription. Refunds are not available
                  for customers 15 days after a purchase. Refunds are not
                  available for accounts which have violated the terms of
                  service; violations are determined at LMS’s sole discretion.
                  If LMS determines that you are abusing our refund policy, we
                  reserve the right to suspend or terminate your account and
                  refuse or restrict any and all current or future use of the
                  Service without delivering a refund. To request a refund or to
                  ask a question regarding the 15-day money back guarantee,
                  email Support at{''}
                  <a href="mailto:info@lmsgh.net.">info@lmsgh.net.</a>
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 className="subtitle">Conditions of Use</h6>
            <ol>
              <li>
                <h6 className="subtitle">User Conduct:</h6>
                <p>
                  You are solely responsible for all code, video, images,
                  information, data, text, software, music, sound, photographs,
                  graphics, messages or other materials (“content”) that you
                  upload, post, publish or display (hereinafter, “upload”) or
                  email or otherwise use via the Service. LMS reserves the right
                  to investigate and take appropriate legal action against
                  anyone who, in our sole discretion, violates this provision,
                  including without limitation, removing the offending content
                  from the Service, suspending or terminating the account of
                  such violators and reporting you to the law enforcement
                  authorities. You agree to not use the Service to:
                </p>
                <ul className="list1">
                  <li>
                    email or otherwise upload any content that (i) infringes any
                    intellectual property or other proprietary rights of any
                    party; (ii) you do not have a right to upload under any law
                    or under contractual or fiduciary relationships; (iii)
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment; (iv) poses or creates a
                    privacy or security risk to any person; (v) constitutes
                    unsolicited or unauthorized advertising, promotional
                    materials, commercial activities and/or sales, “junk mail,”
                    “spam,” “chain letters,” “pyramid schemes,” “contests,”
                    “sweepstakes,” or any other form of solicitation; (vi) is
                    unlawful, harmful, threatening, abusive, harassing,
                    tortious, excessively violent, defamatory, vulgar, obscene,
                    pornographic, libelous, invasive of another’s privacy,
                    hateful racially, ethnically or otherwise objectionable; or
                    (vii) in the sole judgment of LMS, is objectionable or which
                    restricts or inhibits any other person from using or
                    enjoying the Service, or which may expose us or its users to
                    any harm or liability of any type
                  </li>
                  <li>
                    interfere with or disrupt the Service or servers or networks
                    connected to the Service, or disobey any requirements,
                    procedures, policies or regulations of networks connected to
                    the Service
                  </li>
                  <li>
                    violate any applicable local, state, national or
                    international law, or any regulations having the force of
                    law
                  </li>
                  <li>
                    impersonate any person or entity, or falsely state or
                    otherwise misrepresent your affiliation with a person or
                    entity
                  </li>
                  <li>
                    harvest or collect email addresses or other contact
                    information of other users from the Service by electronic or
                    other means for the purposes of sending unsolicited emails
                    or other unsolicited communications
                  </li>
                </ul>
              </li>
              <li>
                <h6 className="subtitle">Fees:</h6>
                <p>
                  To the extent the Service or any portion thereof is made
                  available for any fee, you will be required to select a
                  payment plan and provide LMS information regarding your credit
                  card, Mobile money or other payment instrument. You represent
                  and warrant to LMS that such information is true and that you
                  are authorized to use the payment instrument. You will
                  promptly update your account information with any changes (for
                  example, a change in your billing address, Change of Mobile
                  Money Number or credit card expiration date etc.) that may
                  occur. You agree to pay LMS the amount that is specified in
                  the payment plan (as well as any applicable taxes) in
                  accordance with the terms of such plan and this Terms of
                  Service. You hereby authorize us to bill your payment
                  instrument in accordance with the terms of the applicable
                  payment plan (as well as any applicable taxes) until you
                  terminate your account, and you further agree to pay any
                  charges so incurred. If you dispute any charges you must let
                  us know within thirty (30) days after the date that we charge
                  you. We reserve the right to change our prices. Your continued
                  use of the Service after the price change becomes effective
                  constitutes your agreement to pay the changed amount. You
                  shall be responsible for all taxes associated with the
                  Services.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Recurring Subscriptions:</h6>
                <p>
                  If you select a Service with an auto renewal feature
                  (“Recurring Subscription”), you authorize us to maintain your
                  account information and charge that account automatically upon
                  the renewal of the Service you choose with no further action
                  required by you. In the event that LMS is unable to charge
                  your account as authorized by you when you enrolled in a
                  Recurring Subscription, we, may, in our sole discretion: (i)
                  bill you for your Service and suspend your access to the
                  Service until payment is received, and/or (ii) seek to update
                  your account information through third party sources (i.e.,
                  your bank or a payment processor) to continue charging your
                  account as authorized by you.
                </p>
                <p>
                  We may change the price for Recurring Subscriptions from time
                  to time and will communicate any price changes to you in
                  advance and, if applicable, how to accept those changes. Price
                  changes for Recurring Subscriptions will take effect at the
                  start of the next subscription period following the date of
                  the price change. You accept the new price by continuing to
                  use your Recurring Subscription after the price change takes
                  effect. If you do not agree with the price changes, you have
                  the right to reject the change by cancelling your Recurring
                  Subscription before the price change goes into effect. Please
                  therefore make sure you read any such notification of price
                  changes carefully.
                </p>
                <p>
                  Payments are nonrefundable and there are no refunds or credits
                  for partially used periods. You may cancel a Recurring
                  Subscription at any time, but if you cancel your subscription
                  before the end of the current subscription period, we will not
                  refund any subscription fees already paid to us. Following any
                  cancellation, however, you will continue to have access to the
                  service through the end of your current subscription period.
                  At any time, and for any reason, we may provide a refund,
                  discount, or other consideration to some or all of our users
                  ("credits"). The amount and form of such credits, and the
                  decision to provide them, are at our sole and absolute
                  discretion. The provision of credits in one instance does not
                  entitle you to credits in the future for similar instances,
                  nor does it obligate us to provide credits in the future,
                  under any circumstance.
                </p>
                <p>
                  If you purchase any Service through a mobile purchase or
                  third-party marketplace (e.g., through the Apple App Store or
                  Google Play Store), the refund policy applicable to that
                  third-party marketplace will apply, unless otherwise
                  explicitly stated by us. Except as otherwise explicitly stated
                  by us, the third-party marketplace will be solely responsible
                  for making refunds under its refund policy, and we will have
                  no refund obligations. LMS disclaims any responsibility or
                  liability related to any third-party marketplace's refund
                  policy or the third party's compliance or noncompliance with
                  such policy.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Commercial Use:</h6>
                <p>
                  Unless otherwise expressly authorized herein or by us in
                  writing, you agree not to display, distribute, license,
                  perform, publish, reproduce, duplicate, copy, create
                  derivative works from, modify, sell, resell, exploit, transfer
                  or upload for any commercial purposes, any portion of the
                  Service, use of the Service, or access to the Service. The
                  Service is solely for your non-commercial, personal, educative
                  use.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 className="subtitle">Intellectual Property Rights</h6>
            <ol>
              <li>
                <h6 className="subtitle">
                  Service Content, Software and Trademarks:
                </h6>
                <p>
                  You acknowledge and agree that the Service may contain content
                  or features (“Service Content”) that are protected by
                  copyright, patent, trademark, trade secret or other
                  proprietary rights and laws. Except as expressly authorized by
                  us, you agree not to modify, copy, frame, scrape, rent, lease,
                  loan, sell, distribute or create derivative works based on the
                  Service or the Service Content, in whole or in part. Any use
                  of the Service or the Service Content other than as
                  specifically authorized herein is strictly prohibited. The
                  content, technology and software underlying the Service or
                  distributed in connection therewith are the property of LMS,
                  our affiliates and our partners (the “Software”). You agree
                  not to copy, modify, create a derivative work of, reverse
                  engineer, reverse assemble or otherwise attempt to discover
                  any source code, sell, assign, sublicense, or otherwise
                  transfer any right in the application. Any rights not
                  expressly granted herein are reserved by us.
                </p>
                <p>
                  The LMS name and logos are trademarks and service marks of LMS
                  (collectively the “LMS Trademarks”). Other LMS, product, and
                  service names and logos used and displayed via the Service may
                  be trademarks or service marks of their respective owners who
                  may or may not endorse or be affiliated with or connected to
                  us. Nothing in this Terms of Service or the Service should be
                  construed as granting, by implication, estoppel, or otherwise,
                  any license or right to use any of LMS Trademarks displayed on
                  the Service, without our prior written permission in each
                  instance. All goodwill generated from the use of our
                  Trademarks will inure to our exclusive benefit.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Third Party Material:</h6>
                <p>
                  Under no circumstances will LMS be liable in any way for any
                  content or materials of any third parties (including users),
                  including, but not limited to, for any errors or omissions in
                  any content, or for any loss or damage of any kind incurred as
                  a result of the use of any such content. Without limiting the
                  foregoing, LMS and its designees will have the right to remove
                  any content that violates these Terms of Service or is deemed
                  by us, in its sole discretion, to be otherwise objectionable.
                  You agree that you must evaluate, and bear all risks
                  associated with, the use of any content, including any
                  reliance on the accuracy, completeness, or usefulness of such
                  content.
                </p>
              </li>
              <li>
                <h6 className="subtitle">Copyright Complaints:</h6>
                <p>
                  LMS respects the intellectual property of others. If you
                  believe in good faith that any materials on the Sites/platform
                  infringe upon your copyrights, please send the following
                  information to our Agent at
                  <a href="mailto:info@lmsgh.net">info@lmsgh.net.</a>
                </p>
                <ul>
                  <li>
                    Identification of the copyrighted work claimed to have been
                    infringed, or, if multiple copyrighted works, a
                    representative list of such works
                  </li>
                  <li>
                    Identification of the material that is claimed to be
                    infringing or to be the subject of infringing activity and
                    that is to be removed or access to which is to be disabled,
                    and information reasonably sufficient to permit us to locate
                    the material
                  </li>
                  <li>Your address, telephone number, and email address</li>
                  <li>
                    A statement by you that you have a good faith belief that
                    use of the material in the manner complained of is not
                    authorized by you.
                  </li>
                </ul>
              </li>
              <li>
                <h6 className="subtitle">Repeat Infringer Policy:</h6>
                <p>
                  In accordance with the applicable terms, LMS has adopted a
                  policy of terminating, in appropriate circumstances and at our
                  sole discretion, users who are deemed to be repeat infringers.
                  We may also at its sole discretion limit access to the Service
                  and/or terminate the memberships of any users who infringe any
                  intellectual property rights, whether or not there is any
                  repeat infringement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 className="subtitle">Indemnity and Release</h6>
            <p>
              To the fullest extent permitted by law, you agree to release,
              indemnify and hold LMS and its affiliates and their officers,
              employees, directors and agent harmless from any and all losses,
              damages, expenses, including reasonable attorneys’ fees, rights,
              claims, actions of any kind and injury (including death) arising
              out of or relating to your use of the Service, any User Content,
              your connection to the Service, your violation of these Terms of
              Service or your violation of any rights of another’s.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Disclaimer of Warranties</h6>
            <p>
              YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
              PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS
              OTHERWISE EXPRESSLY PROVIDED HEREIN, KATON EXPRESSLY DISCLAIMS ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
              INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT.
            </p>
            <p>
              KATON MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR
              REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
              SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM
              THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE
              QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
              PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
              EXPECTATIONS.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Limitation of Liability</h6>
            <p>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT KATON WILL NOT BE LIABLE
              FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY
              DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED
              TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE
              LOSSES (EVEN IF KATON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE
              INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF
              SUBSTITUTE PRODUCTS AND SERVICES RESULTING FROM ANY PRODUCTS,
              DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
              RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE;
              (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS
              OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE
              SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO
              EVENT WILL KATON’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES
              OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID MASTERCLASS IN
              THE LAST SIX (6) MONTHS,
            </p>
            <p>
              ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT
              APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
              SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
              REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Binding Arbitration; Class Action Waiver
            </h6>
            <p>
              This Section 8 is intended to be interpreted broadly and governs
              any and all disputes between us including but not limited to
              claims arising out of or relating to any aspect of the
              relationship between us or the Terms of Service or the Service,
              whether based in contract, tort, statute, fraud, misrepresentation
              or any other legal theory. The only disputes excluded from this
              broad prohibition are the litigation of certain intellectual
              property and small court claims, as provided below.
            </p>
            <p>
              If you have any dispute with us, you agree that before taking any
              formal action, you will contact at
              <a href="mailto:info@lmsgh.net">info@lmsgh.net</a>/
              <a href="tel:+0800790555">0800790555</a> , GA-032-1300, HP
              Nyametei F809/11TH Street Osu-Accra and provide a brief, written
              description of the dispute and your contact information (including
              your username, if your dispute relates to an account). Except for
              intellectual property and small claims court claims, the parties
              agree to use their best efforts to settle any dispute, claim,
              question, or disagreement directly through consultation and good
              faith negotiations shall be a condition to either party initiating
              a lawsuit or arbitration.
            </p>
            <p>
              All disputes, claims, or controversies arising out of or relating
              to the Terms of Service or the Service that are not resolved by
              the procedures identified above shall be resolved by individual
              (not group) binding arbitration.{' '}
            </p>
            <p>
              LMS shall have exclusive authority to resolve all disputes arising
              out of or relating to the interpretation, applicability,
              enforceability, or formation of these Terms of Service, including
              but not limited to any claim that all or any part of these Terms
              of Services are void or voidable, or whether a claim is subject to
              arbitration.
            </p>
            <p>
              You and we agree that the arbitration shall be conducted in the
              party’s respective individual capacities only and not as a class
              action or other representative action, and the parties expressly
              waive their right to file a class action or seek relief on a class
              basis. YOU AND KATON AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
              OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING.
            </p>
            <p>
              YOU UNDERSTAND AND AGREE THAT THE ABOVE DISPUTE PROCEDURES SHALL
              BE YOUR SOLE REMEDY IN THE EVENT OF DISPUTE BETWEEN YOU AND KATON
              REGARDING ANY ASPECT OF THE SERVICE (INCLUDING THE REGISTRATION
              PROCESS) AND THAT YOU ARE WAIVING YOUR RIGHT TO LEAD OR
              PARTICIPATE IN A LAWSUIT INVOLVING OTHER PERSONS, SUCH AS A CLASS
              ACTION.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Termination</h6>
            <p>
              You agree that LMS, in its sole discretion, may suspend or
              terminate your account (or any part thereof) or use of the Service
              for any reason, including, without limitation, for lack of use or
              if we believe that you have violated or acted inconsistently with
              the letter or spirit of these Terms of Service. Any suspected
              fraudulent, abusive or illegal activity that may be grounds for
              termination of your use of Service, may be referred to appropriate
              law enforcement authorities. LMS may also in its sole discretion
              and at any time discontinue providing the Service, or any part
              thereof, with or without notice. You agree that any termination of
              your access to the Service under any provision of this Terms of
              Service may be effected without prior notice, and acknowledge and
              agree that we may immediately deactivate or delete your account
              and all related information and/or bar any further access to any
              files or the Service. Further, you agree that we will not be
              liable to you or any third party for any termination of your
              access to the Service.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Disputes Between Users</h6>
            <p>
              You agree that you are solely responsible for your interactions
              with any other user in connection with the Service and we will
              have no liability or responsibility with respect thereto. We
              reserve the right, but has no obligation, to become involved in
              any way with disputes between you and any other user of the
              Service.
            </p>
          </li>
          <li>
            <h6 className="subtitle">General</h6>
            <p>
              These Terms of Service constitute the entire agreement between you
              and LMS and govern your use of the Service/platform, superseding
              any prior agreements between you and us with respect to the
              Service. You also may be subject to additional terms and
              conditions that may apply when you use affiliate or third-party
              services, third party content or third-party software. These Terms
              of Service will be governed by the laws of the country without
              regard to its conflict of law provisions. If any provision of
              these Terms of Service is found by a court of competent
              jurisdiction to be invalid, the parties nevertheless agree that
              the court should endeavor to give effect to the parties’
              intentions as reflected in the provision, and the other provisions
              of these Terms of Service remain in full force and effect. You
              agree that regardless of any statute or law to the contrary, any
              claim or cause of action arising out of or related to use of the
              Service or these Terms of Service must be filed within one (1)
              year after such claim or cause of action arose or be forever
              barred. A printed version of this agreement and of any notice
              given in electronic form will be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions as
              other business documents and records originally generated and
              maintained in printed form. You may not assign this Terms of
              Service without the prior written consent of LMS, but we may
              assign or transfer this Terms of Service, in whole or in part,
              without restriction. The section titles in these Terms of Service
              are for convenience only and have no legal or contractual effect.
              Notices to you may be made via either email or regular mail. Under
              no circumstances shall LMS be held liable for any delay or failure
              in performance resulting directly or indirectly from an event
              beyond its reasonable control. The Service may also provide
              notices to you of changes to these Terms of Service or other
              matters by displaying notices or links to notices generally on the
              Service.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Questions? Concerns? Suggestions?</h6>
            <p>
              Please contact us at GA-032-1300, HP Nyametei F809/11TH Street
              Osu-Accra, ( <a href="mailto:info@lmsgh.net">info@lmsgh.net</a> )
              or through our helpline <a href="tel:+0800790555">0800790555</a> .
              to report any violations of these Terms of Service or to pose any
              questions regarding this Terms of Service or the Service.
            </p>
          </li>
        </ol>
      </div>
    </section>
  )
}

export default Terms
