import { useNavigate } from 'react-router-dom'
import './notFound.scss'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="container py-5 main_section vh-100 d-flex justify-content-center align-items-center">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h1 className="mb-4">OOPS !</h1>
            <p className="mt-2 mb-4">404 - THE PAGE CAN'T BE FOUND</p>
            <button
              className="edit--btn viewAllBtn rounded py-2 ms-3 mt-2"
              onClick={() => navigate('/')}
            >
              Go TO HOMEPAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
