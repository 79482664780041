import { Link } from 'react-router-dom'
import './ContactForm.css'

const ContactForm = () => {
  return (
    <div className="container  mt-4 mb-4">
      <div>
        <div id="form_Contact" className="card contact-card w-50">
          <div className="card-body ">
            <h5 className="card-title text-center">Contact Us</h5>
            <p className="card-subtitle subpara mb-4 text-center">
              Leave a message here
            </p>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
              />
            </div>
            <div className="row mb-3">
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                />
              </div>
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                placeholder="Your Message"
                rows="4"
              ></textarea>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                I agree to the{' '}
                <Link className="tPolicy" to="/privacy-policy">
                  terms and privacy policy
                </Link>
              </label>
            </div>
            <div className="text-center">
              <button type="button" className="btn Msend btn-dark">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
