import styled from 'styled-components'
// lib
import * as styles from 'lib/styles/styles'
import palette from 'lib/styles/palette'
import { mediaQuery } from 'lib/styles/media'


const Logo = () => {
	return (
		<Wrapper >
			<Img src={require('../../../../../assets/images/logo/MOE_Logooo2.png')} alt="Logo" />
		</Wrapper>
	);
}

const Wrapper = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	margin-left: 16px;
	background-color: #fff
	transition: .2s ${styles.transition};
	border-radius: 0px;
	padding: 4px 8px;
	cursor: pointer;

	${mediaQuery(700)} {
		display: none;
	}

	&:focus, &:hover {
		background-color: #fff;
	}
`;

const Img = styled.img`
	height: 44px;
`;

export default Logo