import { FaCalendarAlt, FaCodeBranch, FaUserAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './EducationForum.scss'
const EducationForumCard = ({ className, img, title, text, id }) => {
  return (
    <div className={`post__item ${className}`}>
      <div className="post__item-img">
        <Link to={`/education-forum/${id}`}>
          <img src={img} alt="" />
        </Link>
      </div>
      <div className="post__item-content">
        <ul className="meta-post">
          <li>
            <FaUserAlt /> By Admin
          </li>
          <li>
            <FaCalendarAlt /> 25 April 2022
          </li>
          <li>
            <FaCodeBranch /> Category
          </li>
        </ul>
        <h5 className="title">
          <Link to={`/education-forum/${id}`}>{title}</Link>
        </h5>
        <Link to={`/education-forum/${id}`} className="forum-btn">
          Go to Event
        </Link>
      </div>
    </div>
  )
}

export default EducationForumCard
