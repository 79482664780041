import img1 from "../../images/blog/1.png";
import img2 from "../../images/blog/2.png";
import img3 from "../../images/blog/3.png";
import img4 from "../../images/blog/4.png";
import img5 from "../../images/blog/5.png";
import img6 from "../../images/blog/6.png";
const blog__data = [
  {
    img: img1,
    id: "1",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img2,
    id: "2",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img3,
    id: "3",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img4,
    id: "4",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img5,
    id: "5",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img6,
    id: "6",
    title: "Lorem ipsum dolor sit amet, etetur ditr, sed diam nonumy",
    text: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
];
export default blog__data;
