import img1 from "../../images/event/1.png";
import img2 from "../../images/event/2.png";
import img3 from "../../images/event/3.png";
const related_posts = [
	{
		img: img1,
		id: "1",
		title: "Professor Tom comments on the volunteer B. Snack brand",
		tags: "Social Sciencs",
	},
	{
		img: img2,
		id: "2",
		title: "Making a meaningful difference in patients lives.",
		tags: "Social Sciencs",
	},
	{
		img: img3,
		id: "3",
		title: "Happiness begins with good health",
		tags: "Social Sciencs",
	},
];
export default related_posts;
