import moment from 'moment'
import { BsCalendarWeek } from 'react-icons/bs'
import { GiVirtualMarker } from 'react-icons/gi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import default_icon from '../../assets/images/default_avatar.png'
import default_card_img from '../../assets/images/default_card_img.png'
import { encryptId, getAuthToken } from '../../helpers/authHelper'
import { IMAGE_URL } from '../../helpers/urlHelper'

const LiveClassCard = ({
  ls_image,
  ls_id,
  ls_date,
  ls_time,
  ls_title,
  ls_desc,
  classes,
  ls_teacher,
  ls_roomURL,
  tab,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  const LoginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const handleClassDetailsPage = async () => {
    const isLoggedIn = getAuthToken() ? true : false
    const encryptedId = await encryptId(ls_id)

    if (!isLoggedIn) {
      LoginAlert()
    } else {
      navigate(`/live-class/${encryptedId}`, {
        state: {
          tab,
        },
      })
    }
  }

  const convertTime = (time) => {
    const [hours, minutes] = time.split(':')
    let hours12 = parseInt(hours, 10)
    const ampm = hours12 >= 12 ? 'PM' : 'AM'

    if (hours12 === 0) {
      hours12 = 12
    } else if (hours12 > 12) {
      hours12 %= 12
    }

    const convertedTime = `${hours12}:${minutes} ${ampm}`

    return convertedTime
  }

  return (
    <div className={`live_event__card h-100 bg-white ${classes}`}>
      <div
        className="live_event__card-img"
        onClick={() => handleClassDetailsPage()}
      >
        <Link to={`#`}>
          {ls_image && ls_image != null ? (
            <img src={`${IMAGE_URL}/${ls_image}`} alt="" />
          ) : (
            <img src={default_card_img} alt="" />
          )}
        </Link>

        <span className="map--marker">
          <GiVirtualMarker />
          Test
        </span>
      </div>
      <div className="live_event__card-content">
        <div className="row align-items-center mb-4">
          <div className="col-12">
            <div className="teacher_info d-flex align-items-center">
              <img
                src={
                  ls_teacher?.tc_profilePic
                    ? `${IMAGE_URL}/${ls_teacher?.tc_profilePic}`
                    : default_icon
                }
                style={{ width: '40px', height: '40px' }}
                alt=""
              />
              <span className="ms-2">{ls_teacher?.tc_fullName}</span>
            </div>
          </div>
        </div>

        <h5 className="title" onClick={() => handleClassDetailsPage()}>
          <Link to={`#`}>{ls_title}</Link>
        </h5>
        <div className="d-flex justify-content-start">
          <div className="live-event-meta d-flex align-items-center justify-content-end">
            {console.log('ls_time12', ls_time)}
            <BsCalendarWeek /> {moment(ls_date).format('DD MMM, YYYY')}
            &nbsp;
            {convertTime(ls_time)}
          </div>
        </div>
        <button
          to={`/live-class/${ls_id}`}
          onClick={() => handleClassDetailsPage()}
          className="live-event-btn mt-4 w-100"
        >
          Class Details
        </button>
      </div>
    </div>
  )
}

export default LiveClassCard
