import ButtonLoader from 'components/Common/ButtonLoader'
import SubmitLoader from 'components/Common/SubmitLoader'
import VideoModal from 'components/Common/WatchModal'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import author from '../../assets/images/course/author.png'
import default_img from '../../assets/images/default_card_img.png'
import { checkIdWithEncryption, getUserInfo } from '../../helpers/authHelper'
import {
  getLiveClass,
  getRecordingsByRoom,
  updateLiveClassAPI,
} from '../../helpers/backendHelpers/liveClass'
import { IMAGE_URL } from '../../helpers/urlHelper'
import './LiveClass.scss'

const LiveClassSingle = () => {
  const location = useLocation()
  const tab = location?.state?.tab
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [liveClass, setLiveClass] = useState({})
  const [liveClassDate, setLiveClassDate] = useState({})
  const [error, setError] = useState('')
  const [isVideoRecorded, setIsVideoRecorded] = useState(false)
  const [recordedVideoURL, setrecordedVideoURL] = useState('')
  const userInfo = getUserInfo()
  const [videoModal, setVideoModal] = useState(false)
  const [showJoinClass, setShowJoinClass] = useState(true)
  const [showRecordedClassBtn, setShowRecordedClassBtn] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const [watchClassBtnLoading, setWatchClassBtnLoading] = useState(true)
  const [decryptedId, setDecryptedId] = useState('')

  useEffect(() => {
    setIsLoading(true)
    let idFromRes = checkIdWithEncryption(id, setDecryptedId, navigate)
    if (idFromRes) {
      fetchliveClass(true, idFromRes)
    }
    setIsLoading(false)
  }, [id])

  useEffect(() => {
    const checkFileOnServer = async () => {
      try {
        if (liveClass?.ls_isSessionEnded === 1) {
          setShowJoinClass(true)
        } else if (liveClass?.ls_isSessionEnded === 2) {
          if (tab === '1') {
            setShowJoinClass(true)
          } else {
            setShowJoinClass(false)
          }
          if (!isVideoRecorded) {
            fetchRecordingsByRoom(liveClass?.ls_roomURL)
          } else {
            let videoUrl = `${IMAGE_URL}/live_recording/live_recording/live_recording/6456419bde443c3206d8badf/800453003581/live_800453003581_1.MP4`
            const res = await checkFileIsInServer(videoUrl)
            if (res) {
              setWatchClassBtnLoading(false)
              setShowRecordedClassBtn(true)
              setrecordedVideoURL(
                `live_recording/live_recording/live_recording/6456419bde443c3206d8badf/800453003581/live_800453003581_1.MP4`,
              )
            } else {
              setShowRecordedClassBtn(false)
            }
          }
        }
      } catch (error) {
        // setImageExists(false);
      }
    }

    checkFileOnServer()
  }, [liveClass])

  const checkFileIsInServer = async (url) => {
    try {
      const response = await fetch(url)
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const fetchliveClass = async (isSecondTimeLoader = true, id) => {
    try {
      setIsLoading(isSecondTimeLoader)
      const response = await getLiveClass(id)
      const { liveSession } = response.data
      setLiveClass(liveSession)
      setLiveClassDate(
        new Date(
          `${liveSession?.ls_date},${liveSession?.ls_time}`,
        ).toLocaleString(),
      )
      setIsLoading(false)
      if (liveSession.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
      setLiveClass({})
    }
  }

  const fetchRecordingsByRoom = async (roomId) => {
    try {
      // setIsLoading(true)
      const response = await getRecordingsByRoom(roomId)
      const { status, archive } = response.data

      if (status) {
        const conf_num = archive[0]?.conf_num
        const fileName = `live_${conf_num}_1.MP4`
        let body = {
          ls_isVideoFetched: 1,
          ls_videoUrl: `live_recording/${roomId}/${conf_num}/${fileName}`,
        }
        setIsVideoRecorded(true)
        updateLiveClass(decryptedId, body)
      }
      // setIsLoading(false)
    } catch (error) {
      // setIsLoading(false)
      setError(error)
      setIsLoading(false)
    }
  }

  const updateLiveClass = async (id, body) => {
    let response = await updateLiveClassAPI(id, body)
    if (response?.status) {
      fetchliveClass(false, id)
    }
  }

  const toggleWatchModal = () => {
    setVideoModal(!videoModal)
  }

  const classFinishAlert = async () => {
    const value = await swal(
      'Opps! you are late. This live class has been finished.',
      {
        buttons: {
          defeat: 'Ok',
        },
      },
    )
    switch (value) {
      case 'defeat':
        navigate('/live-class')
        break
      default:
    }
  }

  return (
    <>
      <div style={{ minHeight: '300px' }}>
        {isLoading ? (
          <div
            style={
              isLoading
                ? { position: 'relative', opacity: '0.8', minHeight: '300px' }
                : {}
            }
          >
            <SubmitLoader />
          </div>
        ) : (
          <>
            {noRecords && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: '300px' }}
              >
                <h1>No system activity found.</h1>
              </div>
            )}
            <section className="education-forum-single py-5">
              <VideoModal
                isOpen={videoModal}
                toggle={toggleWatchModal}
                video={recordedVideoURL}
              />
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <article className="education-forum-single-content pe-xl-4">
                      <div className="education-forum-single-content-top">
                        <img
                          src={
                            liveClass?.ls_image && liveClass?.ls_image != null
                              ? `${IMAGE_URL}/${liveClass?.ls_image}`
                              : `${default_img}`
                          }
                          style={{ maxHeight: '600px' }}
                          alt={`${
                            liveClass?.tp_programTitle === undefined
                              ? 'Image'
                              : liveClass?.tp_programTitle
                          }`}
                        />
                        <h3 className="mt-3">{liveClass?.ls_title}</h3>
                      </div>
                      &nbsp; &nbsp;
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="description"
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: liveClass?.ls_desc,
                            }}
                          ></p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4">
                    <aside>
                      <div className="widget">
                        <br />
                        <div className="img-container">
                          <span className="video--btn">
                            <img src={author} alt="Teacher" />
                          </span>
                        </div>
                        <br />
                        <ul className="video-info">
                          <li>
                            <strong>Teacher Name</strong>
                            <span className="text-capitalize">
                              {`${liveClass?.ls_teacher?.tc_fullName}`}
                            </span>
                          </li>
                          <li>
                            <strong>Live Class :</strong>
                            <span className="text-capitalize">
                              {`${liveClass?.ls_title}`}
                            </span>
                          </li>
                          <li>
                            <strong>Date :</strong>
                            <span>
                              {moment(liveClass?.ls_date).format(
                                'DD MMM, YYYY',
                              )}
                            </span>
                          </li>
                          <li>
                            <strong>Time :</strong>
                            <span>
                              {moment
                                .utc(liveClass?.ls_time, 'hh:mm A')
                                .format('hh:mm A')}
                            </span>
                          </li>
                        </ul>
                        <div className="mt-3 pt-2">
                          <>
                            {showJoinClass && (
                              <Link
                                to={`#`}
                                // target="_blank"
                                state={{ id: liveClass?.ls_roomURL }}
                                onClick={() => {
                                  if (
                                    tab === '1' &&
                                    liveClass?.ls_isSessionEnded === '2'
                                  ) {
                                    classFinishAlert()
                                  } else {
                                    window.location = `/live-class-room/${liveClass?.ls_roomURL}?uType=${userInfo?.userType}&uName=${userInfo?.fullName}`
                                  }
                                }}
                              >
                                <button
                                  type="button"
                                  className="enroll-btn cmn--btn w-100"
                                >
                                  {userInfo?.id === liveClass?.tc_id
                                    ? 'Start Class'
                                    : 'Join Class'}
                                </button>
                              </Link>
                            )}
                            {showRecordedClassBtn && (
                              <>
                                <Link to={`#`}>
                                  <button
                                    disabled={watchClassBtnLoading}
                                    onClick={() => {
                                      toggleWatchModal()
                                    }}
                                    type="button"
                                    className="enroll-btn cmn--btn w-100"
                                  >
                                    <div className="d-flex align-items-center justify-content-center">
                                      {watchClassBtnLoading && (
                                        <ButtonLoader></ButtonLoader>
                                      )}

                                      <span>Watch Recorded Class</span>
                                    </div>
                                  </button>
                                </Link>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default LiveClassSingle
