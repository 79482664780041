import QuestionsMCQTest from 'components/Common/QuestionsMCQTest'
import SubmitLoader from 'components/Common/SubmitLoader'
import { getAllQuestionsByPaperID } from 'helpers/backendHelpers/allQuestions'
import { IMAGE_URL } from 'helpers/urlHelper'
import { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const ObjectiveQuestionsModal = ({
  pp_id,
  toggle,
  pdfUrl,
  ppTitle,
  ppSubcategory,
}) => {
  const isOpen = !!pp_id

  const [isLoading, setIsLoading] = useState(false)
  const [questionsData, setQuestionsData] = useState(null)
  const [showObjectivePDF, setShowObjectivePDF] = useState(false)

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setIsLoading(true)
        const response = await getAllQuestionsByPaperID(pp_id)
        console.log(response)
        setQuestionsData(response.data.assignmentQuestions)

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    }

    fetchQuestions()
  }, [pp_id])

  const toggleShowObjectivePDF = () => {
    setShowObjectivePDF(!showObjectivePDF)
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      scrollable
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tutorModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <div className="container">
          <div className="row">
            <div className="video-modal-headers">
              {showObjectivePDF
                ? `${ppSubcategory} - ${ppTitle}`
                : `${ppSubcategory} - ${ppTitle}`}

              <Button
                className="video-modal-button"
                color="dark"
                onClick={toggleShowObjectivePDF}
              >
                {showObjectivePDF ? 'Objective Questions' : 'Original Paper'}
              </Button>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {showObjectivePDF ? (
          <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
            <iframe
              oncontextmenu="return false;"
              id="pdfIframe"
              // onContextMenu={handleContextMenu}
              style={{ height: '100%', width: '100%' }}
              title="test"
              className="embed-responsive-item"
              src={`${IMAGE_URL}/${pdfUrl}#toolbar=0`}
            />
          </div>
        ) : isLoading || questionsData === null ? (
          <div
            style={
              isLoading
                ? {
                    position: 'relative',
                    opacity: '0.8',
                    minHeight: '300px',
                  }
                : {}
            }
          >
            <SubmitLoader />
          </div>
        ) : (
          <QuestionsMCQTest
            questionsData={questionsData}
            isSingleChoice={true}
            handleCloseModalCallback={toggle}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export default ObjectiveQuestionsModal
