import PdfReaderModal from 'components/Common/PdfReaderModal'
import SubmitLoader from 'components/Common/SubmitLoader'
import { getAuthToken, getFullUserInfo } from 'helpers/authHelper'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { getAllPastPaper } from '../../helpers/backendHelpers/pastPaper'
import '../practice/Practice.css'
import FilterPastPaperQuestion from './FilterPastPaperQuestion'
import ObjectiveQuestionsModal from './ObjectiveQuestionsModal'

const PastPaper = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [pastPaper, setPastPaper] = useState([])

  const [showQuestion, setShowQuestion] = useState(false)
  const [error, setError] = useState('')
  const [queAns, setQueAns] = useState([])
  const [noRecords, setNoRecords] = useState(false)
  const [pdfFile, setPdfFile] = useState('')
  const [pdfReaderModal, setPdfReaderModal] = useState(false)
  const isLoggedIn = getAuthToken() ? true : false
  const [objectiveModalPPID, setObjectiveModalPPID] = useState(null)
  const [objectivePDFUrl, setObjectivePDFUrl] = useState('')
  const [ppSubcategory, setPpSubcategory] = useState('')
  const [ppTitle, setPpTitle] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('')

  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    const getPastPapers = async () => {
      try {
        setIsLoading(true)

        const fullInfo = getFullUserInfo()
        const level = fullInfo?.st_level || fullInfo?.tc_level

        let response = await getAllPastPaper(level)
        let { pastPaper } = response.data

        let subjectList = pastPaper.map((paper) => paper.pp_subcategory)
        subjectList = [...new Set(subjectList)]
        subjectList = subjectList.filter((subject) => subject)

        setSubjects(subjectList)

        setPastPaper(pastPaper)
        setIsLoading(false)
        if (pastPaper.length === 0) {
          setNoRecords(true)
        } else {
          setNoRecords(false)
        }
      } catch (error) {
        setError(error)
        setIsLoading(false)
      }
    }

    getPastPapers()
  }, [])

  const togglePdfReaderModal = () => {
    setPdfReaderModal(!pdfReaderModal)
  }

  // Filtering the papers
  let filteredPastPapers = pastPaper
  if (selectedSubject && selectedSubject.length > 0) {
    filteredPastPapers = pastPaper.filter(
      (paper) => paper.pp_subcategory === selectedSubject,
    )
  }

  const location = useLocation()
  const pathName = location.pathname
  const navigate = useNavigate()
  const loginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  return (
    <>
      <section className="practice py-5">
        {objectiveModalPPID ? (
          <ObjectiveQuestionsModal
            pp_id={objectiveModalPPID}
            toggle={() => {
              setObjectiveModalPPID(null)
              setObjectivePDFUrl('')
              setPpSubcategory('')
              setPpTitle('')
            }}
            pdfUrl={objectivePDFUrl}
            ppSubcategory={ppSubcategory}
            ppTitle={ppTitle}
          />
        ) : null}

        <PdfReaderModal
          modalTitle={'Read PDF'}
          isOpen={pdfReaderModal}
          toggle={togglePdfReaderModal}
          pdf={pdfFile}
          type="pdf"
          isLoading={isLoading}
        />
        <div className="container">
          <div className="row books-section">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  <h4 className="m-0">Subjects</h4>
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    onClick={() => setSelectedSubject('')}
                  >
                    Reset
                  </button>
                </div>
                <div className="books-sidebar-widget">
                  <div className="books-wrapper-header">
                    {subjects?.map((subject) => (
                      <button
                        className={`btn mb-2 me-1 col-md-8 ${
                          subject === selectedSubject
                            ? 'btnAssReview'
                            : 'btnAssReviewE'
                        }`}
                        onClick={() => setSelectedSubject(subject)}
                      >
                        <b>{subject}</b>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div>
                {isLoading ? (
                  <div
                    style={
                      isLoading
                        ? {
                            position: 'relative',
                            opacity: '0.8',
                            minHeight: '300px',
                          }
                        : {}
                    }
                  >
                    <SubmitLoader />
                  </div>
                ) : (
                  <>
                    {noRecords && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: '300px' }}
                      >
                        <h1>No system activity found.</h1>
                      </div>
                    )}
                    <div className="row">
                      {!showQuestion ? (
                        <>
                          {filteredPastPapers &&
                            filteredPastPapers.map((data, i) => {
                              return (
                                <>
                                  <div className="col-md-3 mb-3 " key={i}>
                                    <div
                                      style={{
                                        textAlign: 'center',
                                      }}
                                      className="pastPaperBox bg-white p-4 rounded-4"
                                    >
                                      <div className="pastPaperBox_heading">
                                        <h5>
                                          {data.pp_subcategory} -{' '}
                                          {data.pp_title}
                                        </h5>
                                      </div>
                                      <hr className="pastPaperBox_hr" />
                                      &nbsp;
                                      <div className=" d-block mb-2">
                                        <div className="d-flex justify-content-center">
                                          {data.pp_pdf ? (
                                            <Link
                                              to={`#`}
                                              onClick={() => {
                                                if (!isLoggedIn) {
                                                  loginAlert()
                                                } else {
                                                  setObjectiveModalPPID(
                                                    data.pp_id,
                                                  )
                                                  setObjectivePDFUrl(
                                                    data.pp_pdf,
                                                  )
                                                  setPpSubcategory(
                                                    data.pp_subcategory,
                                                  )
                                                  setPpTitle(data.pp_title)
                                                }
                                              }}
                                              className="detailBtn col-md-12"
                                              rel="noopener noreferrer"
                                              style={{
                                                display: 'table-cell',
                                                textAlign: 'center',
                                                // color: '#111d5e',
                                                // backgroundColor: '#ccf2f9',
                                              }}
                                            >
                                              Objectives - Paper 1
                                            </Link>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className=" d-block ">
                                        <div className="d-flex justify-content-center">
                                          {data.pp_writtenPDF ? (
                                            <Link
                                              // to={`#`}
                                              // onClick={() => {
                                              //   setPdfFile(data.pp_writtenPDF)
                                              //   togglePdfReaderModal()
                                              // }}
                                              onClick={() => {
                                                if (!isLoggedIn) {
                                                  loginAlert()
                                                } else {
                                                  setPdfFile(data.pp_writtenPDF)
                                                  togglePdfReaderModal()
                                                }
                                              }}
                                              className="detailBtn mt-3 col-md-12"
                                              rel="noopener noreferrer"
                                              style={{
                                                display: 'table-cell',
                                                textAlign: 'center',
                                              }}
                                            >
                                              Essay - Paper 2
                                            </Link>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                        </>
                      ) : (
                        <FilterPastPaperQuestion
                          queAns={queAns}
                          noRecords={noRecords}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PastPaper
