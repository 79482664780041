import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import {
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import * as Yup from 'yup'

const VerificationForm = (props) => {
  const {
    isOpen,
    toggle,
    pdf,
    type,
    modalTitle,
    handleContinueSubmitting,
    teacherData,
    studentData,
    submitUserType,
    registerStep,
    generateTeacherOTP,
    setRegisterStep,
    generateStudentOTP,
    error,
    errorApi,
    setErrorApi,
    updatedPhoneNumber,
    setUpdatedPhoneNumber,
  } = props

  const VerificationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
      .required('Please enter your phone number'),
  })

  const submitData = async (values, { setSubmitting }) => {
    const { phoneNumber } = values
    // Proceed with OTP generation
    // toggle()
    try {
      if (submitUserType === 1) {
        if (!teacherData?.tc_isEmailVerified) {
          let body = {
            email: teacherData?.tc_email,
            phoneNumber,
            type: 1,
          }
          await generateTeacherOTP(body, 2)
        } else if (!teacherData?.tc_isPhoneVerified) {
          let body = {
            email: teacherData?.tc_email,
            type: 2,
            phoneNumber,
          }
          await generateTeacherOTP(body, 3)
          setUpdatedPhoneNumber(phoneNumber)
        }
      }
    } finally {
      setSubmitting(false)
    }

    //The below is for student verification
    // else if (submitUserType === 2) {
    //   if (!studentData?.st_isEmailVerified) {
    //     let body = {
    //       email: studentData?.st_email,
    //       type: 1,
    //     }
    //     await generateStudentOTP(body, 2)
    //   } else if (!studentData?.st_isPhoneVerified) {
    //     let body = {
    //       email: studentData?.st_email,
    //       type: 2,
    //     }
    //     await generateStudentOTP(body, 3)
    //   }
    // }
  }

  return (
    // <Modal
    //   isOpen={isOpen}
    //   size="md"
    //   role="dialog"
    //   autoFocus={true}
    //   centered={true}
    //   className="tutorModal"
    //   tabIndex="-1"
    //   toggle={() => {
    //     toggle()
    //   }}
    // >
    //   <ModalHeader
    //     toggle={() => {
    //       toggle()
    //     }}
    //   >
    //     {modalTitle}
    //   </ModalHeader>
    //   <ModalBody>
    //     <div className="d-flex flex-column my-4">
    //       <p>Please verify your account details.</p>
    //       <br />
    //       <br />
    //       <div className="d-flex justify-content-between me-5">
    //         <p className="fw-bolder ">
    //           {submitUserType === 1
    //             ? teacherData?.tc_email
    //             : studentData?.st_email}
    //         </p>
    //         <p
    //           className={`${
    //             submitUserType === 1
    //               ? teacherData?.tc_isEmailVerified
    //                 ? 'text-success'
    //                 : ''
    //               : studentData?.st_isEmailVerified
    //               ? 'text-success'
    //               : ''
    //           }`}
    //         >
    //           {submitUserType === 1
    //             ? teacherData?.tc_isEmailVerified
    //               ? 'Verified'
    //               : 'Pending'
    //             : studentData?.st_isEmailVerified
    //             ? 'Verified'
    //             : 'Pending'}
    //         </p>
    //       </div>
    //       <div className="d-flex justify-content-between me-5">
    //         <p className="fw-bolder ">
    //           {submitUserType === 1
    //             ? `+${teacherData?.tc_countryCode} ${teacherData?.tc_phoneNumber}`
    //             : `+${studentData?.st_countryCode} ${studentData?.st_phoneNumber}`}
    //         </p>
    //         <p
    //           className={`${
    //             submitUserType === 1
    //               ? teacherData?.tc_isPhoneVerified
    //                 ? 'text-success'
    //                 : ''
    //               : studentData?.st_isPhoneVerified
    //               ? 'text-success'
    //               : ''
    //           }`}
    //         >
    //           {submitUserType === 1
    //             ? teacherData?.tc_isPhoneVerified
    //               ? 'Verified'
    //               : 'Pending'
    //             : studentData?.st_isPhoneVerified
    //             ? 'Verified'
    //             : 'Pending'}
    //         </p>
    //       </div>
    //       <div className="d-flex justify-content-center mt-2">
    //         <button
    //           onClick={async () => {
    //             toggle()

    //             if (submitUserType === 1) {
    //               if (!teacherData?.tc_isEmailVerified) {
    //                 let body = {
    //                   email: teacherData?.tc_email,
    //                   type: 1,
    //                 }
    //                 await generateTeacherOTP(body, 2)
    //               } else if (!teacherData?.tc_isPhoneVerified) {
    //                 let body = {
    //                   email: teacherData?.tc_email,
    //                   type: 2,
    //                 }
    //                 await generateTeacherOTP(body, 3)
    //               }
    //             } else if (submitUserType === 2) {
    //               if (!studentData?.st_isEmailVerified) {
    //                 let body = {
    //                   email: studentData?.st_email,
    //                   type: 1,
    //                 }
    //                 await generateStudentOTP(body, 2)
    //               } else if (!studentData?.st_isPhoneVerified) {
    //                 let body = {
    //                   email: studentData?.st_email,
    //                   type: 2,
    //                 }
    //                 await generateStudentOTP(body, 3)
    //               }
    //             }
    //           }}
    //           className="edit--btn viewAllBtn rounded py-2 w-25 ms-3"
    //         >
    //           Verify Now
    //         </button>
    //       </div>
    //     </div>
    //   </ModalBody>
    // </Modal>

    //New code for modal
    <Modal
      isOpen={isOpen}
      size="md"
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tutorModal"
      tabIndex="-1"
      toggle={() => {
        toggle()
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle()
        }}
      >
        {modalTitle}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column my-4">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <p>Please verify your mobile number</p>
            </div>
            {errorApi && (
              <div className="d-flex justify-content-center align-items-center mt-3">
                <p className="form-err-msg">{errorApi}</p>
              </div>
            )}
            <div>
              <p className="fw-bolder">
                <Formik
                  initialValues={{
                    phoneNumber: teacherData?.tc_phoneNumber || '',
                  }}
                  validationSchema={VerificationSchema}
                  // onSubmit={async (values, { setSubmitting }) => {
                  //   setSubmitting(true)
                  //   await submitData(values)
                  //   setSubmitting(false)
                  //   toggle()
                  // }}
                  onSubmit={submitData}
                >
                  {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <Form
                      className="mt-3 pt-2"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      {error && (
                        <div className="input--group text-danger">
                          <p className="form-err-msg">{error}</p>
                        </div>
                      )}
                      <div className="input--group">
                        <InputGroup>
                          <InputGroupText className="me-1">
                            +{teacherData?.tc_countryCode}
                          </InputGroupText>
                          <Field
                            className={`form-control ${
                              errors.phoneNumber && touched.phoneNumber
                                ? 'form-err'
                                : ''
                            }`}
                            name="phoneNumber"
                            placeholder="Enter phone number"
                          />
                        </InputGroup>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <div className="form-err-msg ms-5">
                            {errors.phoneNumber}
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="edit--btn viewAllBtn rounded py-2 w-50 ms-3"
                        >
                          {isSubmitting ? (
                            <>
                              <Spinner size="sm" className="me-2" />
                              Verifying...
                            </>
                          ) : (
                            'Verify Now'
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

VerificationForm.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default VerificationForm
