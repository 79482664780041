import img1 from "../../images/event/1.png";
import img2 from "../../images/event/2.png";
import img3 from "../../images/event/3.png";
import img4 from "../../images/event/4.png";
import img5 from "../../images/event/5.png";
import img6 from "../../images/event/6.png";
import img7 from "../../images/event/7.png";
import img8 from "../../images/event/8.png";
const events = [
  {
    img: img7,
    id: "7",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img8,
    id: "8",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img1,
    id: "1",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img2,
    id: "2",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img3,
    id: "3",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img4,
    id: "4",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img5,
    id: "5",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img6,
    id: "6",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
  {
    img: img7,
    id: "7",
    zone: "Campala",
    date: "25 April 2022",
    title:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
    txt: "Lorem ipsum dolor sit amet, consetetur ditr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
  },
];
export default events;
