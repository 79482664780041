const category_list = [
	{
		title: "Student",
		link: "#",
		sub_category: [
			{
				title: "Sub Category 1",
				link: "#",
			},
			{
				title: "Sub Category 2",
				link: "#",
			},
			{
				title: "Sub Category 3",
				link: "#",
			},
		],
	},
	{
		title: "Seminar",
		link: "#",
		sub_category: [
			{
				title: "Sub Category 1",
				link: "#",
			},
			{
				title: "Sub Category 2",
				link: "#",
			},
			{
				title: "Sub Category 3",
				link: "#",
			},
		],
	},
	{
		title: "Research",
		link: "#",
		sub_category: [
			{
				title: "Sub Category 1",
				link: "#",
			},
			{
				title: "Sub Category 2",
				link: "#",
			},
			{
				title: "Sub Category 3",
				link: "#",
			},
		],
	},
	{
		title: "Event",
		link: "#",
		sub_category: [
			{
				title: "Sub Category 1",
				link: "#",
			},
			{
				title: "Sub Category 2",
				link: "#",
			},
			{
				title: "Sub Category 3",
				link: "#",
			},
		],
	},
];
export default category_list;
