import blog__data from '../../assets/images/data/blog'
import EducationForumCard from './EducationForumCard'

const EducationForum = () => {
  return (
    <section className="education-forum p-5">
      <div className="container">
        <div className="row g-4">
          {blog__data.map((item, i) => (
            <div className="col-lg-4 col-md-6" key={i}>
              <EducationForumCard className="m-0" {...item} />
            </div>
          ))}
        </div>
        <div className="text-center mt-5">
          <button className="cmn--btn py-3 px-md-5" type="button">
            Load More
          </button>
        </div>
      </div>
    </section>
  )
}

export default EducationForum
