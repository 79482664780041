// components
import styled from 'styled-components'
import palette from 'lib/styles/palette'

import Item from './commons/footer/Item'
import MoveBtn from './commons/footer/MoveBtn'

const Wrapper = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
`;

const Footer = ({ title, nowPage, totalPage, onPageMove }: Props) => {
  return (
    <Wrapper>
      <MoveBtn type="PREV" onClick={() => onPageMove("PREV")} />
      <Item text={title} />
      <Item text={`${nowPage} / ${totalPage}`} />
      <MoveBtn type="NEXT" onClick={() => onPageMove("NEXT")} />
    </Wrapper>
  );
}

interface Props {
  title: string;
  nowPage: number;
  totalPage: number;
  onPageMove: (type: "PREV" | "NEXT") => void;
}

export default Footer