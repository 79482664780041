import SubmitLoader from 'components/Common/SubmitLoader'
import { Field, Form, Formik } from 'formik'
import useAllContentCategories from 'hooks/useAllContentCategories'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { BsCalendarWeek, BsChevronDown } from 'react-icons/bs'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import swal from 'sweetalert'
import * as Yup from 'yup'
import {
  getAuthToken,
  getFullUserInfo,
  getUserInfo,
} from '../../helpers/authHelper'
import {
  createSelfAssessment,
  getAllSelfAssessment,
} from '../../helpers/backendHelpers/selfAssessment'
import '../practice/McqTest.scss'

const PastPaperFilterQuestionSchema = Yup.object().shape({
  mainCategory: Yup.string().optional(),
  category: Yup.string().optional(),
  subcategory: Yup.string().optional(),
  topic: Yup.string().optional(),
})

const SelfAssessment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selfAssessment, setSelfAssessment] = useState([])
  const [selectCategory, setSelectCategory] = useState([])

  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubCategory, setSelectedSubCategory] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')

  const [showQuestion, setShowQuestion] = useState(false)

  const [error, setError] = useState('')
  const [noRecords, setNoRecords] = useState(false)
  const userInfo = getUserInfo()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  const { getClasses, getSubjects, getTopics } = useAllContentCategories()
  const [subjects, setSubjects] = useState([])
  const [topics, setTopics] = useState([])

  const loginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const getSelfAssessmentData = async () => {
    try {
      setIsLoading(true)
      let response = await getAllSelfAssessment()
      let { selfAssessment } = response.data
      setSelfAssessment(selfAssessment)
      setIsLoading(false)
      if (selfAssessment.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const handleCategoryChange = (e) => {
    setSelectedSubCategory('')
    setSelectedTopic('')

    const category = e.target.value
    setSelectedCategory(category)

    setSelectedSubCategory('')

    const fullUserInfo = getFullUserInfo()
    const levelName = fullUserInfo?.st_level || fullUserInfo?.tc_level

    const newSubjects = getSubjects(levelName, category)
    setSubjects(newSubjects)
  }

  const handleSubCategoryChange = (e) => {
    setSelectedTopic('')

    const subCategory = e.target.value
    setSelectedSubCategory(subCategory)

    const fullUserInfo = getFullUserInfo()
    const levelName = fullUserInfo?.st_level || fullUserInfo?.tc_level

    const newTopics = getTopics(levelName, selectedCategory, subCategory)
    setTopics(newTopics)
  }

  const handleTopicChange = (e) => {
    const topic = e.target.value
    setSelectedTopic(topic)
  }

  useEffect(() => {
    getSelfAssessmentData()
  }, [])

  useEffect(() => {
    const getAllClass = async () => {
      try {
        const fullUserInfo = getFullUserInfo()
        const mainCategory = fullUserInfo?.st_level || fullUserInfo?.tc_level

        setIsLoading(true)

        const classList = getClasses(mainCategory)
        classList.sort()

        setSelectCategory(classList)
        setIsLoading(false)
      } catch (error) {
        setError(error)
        setIsLoading(false)
      }
    }

    getAllClass()
  }, [getClasses])

  const handleCreateSelfAssessmentForm = async () => {
    if (!getAuthToken()) {
      loginAlert()
    } else {
      try {
        if (!selectedCategory || !selectedSubCategory) {
          toast.error('Class and Subject Required', { autoClose: 5000 })
          return
        }

        setIsLoading(true)
        let data = {
          sa_category: selectedCategory,
          sa_subCategory: selectedSubCategory,
          sa_topic: selectedTopic,
        }
        let response = await createSelfAssessment(data)

        let { status } = response
        if (status) {
          getSelfAssessmentData()
        }

        setIsLoading(false)
      } catch (error) {
        let message = 'Something went wrong!!'
        toast.error(error?.response?.data?.message || message, {
          autoClose: 5000,
        })
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        containerStyle={{
          top: 80,
          zIndex: 100000,
        }}
      />

      <section className="practice py-5">
        <div className="container">
          <div className="row books-section">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-widget text-center">
                  <h4 className="m-0">Self Assessments</h4>
                </div>
                <div className="books-sidebar-widget">
                  <div className="books-wrapper-header">
                    <Formik
                      initialValues={{
                        category: '',
                        subcategory: '',
                        topic: '',
                      }}
                      validationSchema={PastPaperFilterQuestionSchema}
                      onSubmit={(values, actions) => {
                        handleCreateSelfAssessmentForm()
                      }}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        isLoggedIn,
                        values,
                        handleBlur,
                      }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                          <div className="filter-form-wrapper-pastPaper">
                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="category"
                                  className={`form--control ${
                                    errors.category && touched.category
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={
                                    selectedCategory
                                      ? selectedCategory
                                      : 'Select Category'
                                  }
                                  onChange={handleCategoryChange}
                                >
                                  <option>
                                    {`${
                                      errors.category && touched.category
                                        ? 'Select a Category Must'
                                        : 'Class/Grade'
                                    }`}
                                  </option>
                                  {selectCategory &&
                                    selectCategory.map((category) => (
                                      <option value={category} key={category}>
                                        {category}
                                      </option>
                                    ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="subcategory"
                                  className={`form--control ${
                                    errors.subcategory && touched.subcategory
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={
                                    selectedSubCategory
                                      ? selectedSubCategory
                                      : ''
                                  }
                                  onChange={handleSubCategoryChange}
                                  disabled={
                                    !selectedCategory || selectedCategory === ''
                                  }
                                >
                                  <option>
                                    {`${
                                      errors.subcategory && touched.subcategory
                                        ? 'Select a Sub Category Must'
                                        : 'Subject'
                                    }`}
                                  </option>
                                  {subjects.map((subject) => (
                                    <option key={subject} value={subject}>
                                      {subject}
                                    </option>
                                  ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="select--item ">
                              <div className="inner">
                                <Field
                                  as="select"
                                  name="topic"
                                  className={`form--control ${
                                    errors.topic && touched.topic
                                      ? 'form-err'
                                      : ''
                                  }`}
                                  value={selectedTopic ? selectedTopic : ''}
                                  onChange={handleTopicChange}
                                  disabled={
                                    !selectedSubCategory ||
                                    selectedSubCategory === ''
                                  }
                                >
                                  <option>
                                    {`${
                                      errors.topic && touched.topic
                                        ? 'Select a Sub Category Must'
                                        : 'Topic'
                                    }`}
                                  </option>
                                  {topics.map((topic) => (
                                    <option key={topic} value={topic}>
                                      {topic}
                                    </option>
                                  ))}
                                </Field>
                                <span className="caret-icon">
                                  <BsChevronDown />
                                </span>
                              </div>
                            </div>

                            <div className="btn-item">
                              <button
                                // onClick={() => {
                                //   handleCreateSelfAssessmentForm()
                                // }}
                                className="cmn--btn"
                                type="submit"
                              >
                                Generate Paper
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div>
                {isLoading ? (
                  <div
                    style={
                      isLoading
                        ? {
                            position: 'relative',
                            opacity: '0.8',
                            minHeight: '300px',
                          }
                        : {}
                    }
                  >
                    <SubmitLoader />
                  </div>
                ) : (
                  <>
                    {noRecords && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: '300px' }}
                      >
                        <h1>No system activity found.</h1>
                      </div>
                    )}
                    <div className="row">
                      {!showQuestion ? (
                        <>
                          {selfAssessment &&
                            selfAssessment.map((data, i) => {
                              return (
                                <>
                                  <div className="col-md-6 mb-3 " key={i}>
                                    <div className="pastPaperBox bg-white p-4 rounded-4">
                                      <h4>{data.sa_title}</h4>
                                      &nbsp;
                                      <div className="d-flex justify-content-between">
                                        <p>{data.sa_subCategory}</p>
                                        {data.sa_score != null ? (
                                          <p className="theme-text">
                                            Score: {data.sa_score}
                                            {/* {data.sa_totalMarks} */}
                                          </p>
                                        ) : (
                                          ''
                                          // <p>Marks : {data.sa_totalMarks}</p>
                                        )}
                                      </div>
                                      <div className=" d-block ">
                                        <div className="d-flex justify-content-end">
                                          <span className="me-auto d-flex align-items-center">
                                            <BsCalendarWeek />
                                            &nbsp;{' '}
                                            {moment(data.sa_date_time).format(
                                              'DD MMM, YYYY',
                                            )}
                                          </span>
                                          {data.sa_score ? (
                                            <Link
                                              className="checkResultBtn"
                                              rel="noopener noreferrer"
                                              style={{ display: 'table-cell' }}
                                            >
                                              Done
                                            </Link>
                                          ) : (
                                            <Link
                                              // to={`/pastQuestion/${data?.sa_id}`}
                                              className="detailBtn"
                                              onClick={() => {
                                                window.open(
                                                  `/selfAssessmentTest/${data?.sa_id}/${userInfo?.id}`,
                                                  'Popup',
                                                  'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,maximize=1',
                                                )
                                              }}
                                              rel="noopener noreferrer"
                                              style={{ display: 'table-cell' }}
                                            >
                                              Start Exam
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                        </>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SelfAssessment
