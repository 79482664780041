import { GoPrimitiveDot } from 'react-icons/go'
import { Link } from 'react-router-dom'
import order_history from '../../assets/images/data/order-history'
import DashboardSidebar from '../../components/DashboardSidebar'

const OrderHistory = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="d-flex flex-wrap">
          <DashboardSidebar />
          <div className="dashboard-article">
            <div className="card cmn--card">
              <div className="card-header py-3">
                <h5 className="card-title">Order History</h5>
              </div>
              <div className="card-body">
                <div className="py-3 px-2">
                  <div className="row g-3 g-md-4 g-lg-3 g-xl-4">
                    {order_history &&
                      order_history.map(
                        ({ link, img, price, date, title }, i) => (
                          <div className="col-sm-6 col-lg-12 col-xl-6" key={i}>
                            <Link to={link} className="history__item">
                              <div className="history__item-img">
                                <img src={img} alt="" />
                              </div>
                              <div className="history__item-cont">
                                <h5 className="title">{title}</h5>
                                <span className="price">Price: {price}</span>
                                <span className="purchased-on">
                                  <GoPrimitiveDot /> Purchased on {date}
                                </span>
                              </div>
                            </Link>
                          </div>
                        ),
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHistory
