import ButtonLoader from 'components/Common/ButtonLoader'
import SubmitLoader from 'components/Common/SubmitLoader'
import { Field, Form, Formik } from 'formik'
import {
  createBlogAPI,
  createComment,
  getCommentsByBlogId,
  likeBlogAPI,
} from 'helpers/backendHelpers/blog'
import {
  createGroupAPI,
  deleteGroupByAdmin,
  exitGroup,
  getGroupsBySchoolAndClass,
  getSingleGroupDetails,
  joinGroupAPI,
} from 'helpers/backendHelpers/group'
import { getGroupMemberByStudentAPI } from 'helpers/backendHelpers/groupMember'
import { getStudentsByClassAndSchoolAPI } from 'helpers/backendHelpers/student'
import { IMAGE_URL } from 'helpers/urlHelper'
import moment from 'moment'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useEffect, useState } from 'react'
import { BiCommentDetail } from 'react-icons/bi'
import { BsCalendarWeek } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { MdOutlineGroupAdd } from 'react-icons/md'
import { SlLike } from 'react-icons/sl'
import OwlCarousel from 'react-owl-carousel'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import swal from 'sweetalert'
import * as Yup from 'yup'
import default_icon from '../../assets/images/default_avatar.png'
import {
  getAuthToken,
  getFullUserInfo,
  getUserInfo,
} from '../../helpers/authHelper'
import './group.scss'

const Group = () => {
  const userInfo = getUserInfo()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  const [groupList, setGroupList] = useState([])
  const [relatedGroupList, setRelatedGroupList] = useState([])
  const [classMates, setClassMatesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const fullUserInfo = getFullUserInfo()
  const [modal, setModal] = useState(false)
  const [blogModal, setBlogModal] = useState(false)
  const [singleGroupDetails, setSingleGroupDetails] = useState({})
  const [temp, setTemp] = useState(1)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [noRecords, setNoRecords] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [noRecordsForComment, setNoRecordsForComment] = useState(false)
  const [isCommentLoading, setIsCommentLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [comment, setComment] = useState('')

  const [blogForm, setBlogForm] = useState({
    bl_title: '',
    bl_desc: '',
    bl_image: { fileName: '', file: {} },
  })

  useEffect(() => {
    fetchGroupMemberByStudent()
    fetchGroupMemberBySchoolAndClass()
    fetchStudentsByClassAndDivAPI()
  }, [])

  const fetchGroupMemberByStudent = async () => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      let response = await getGroupMemberByStudentAPI(userInfo?.id)
      let { groupMember, group } = response.data
      groupMember.map((data) => {
        let tempData =
          data.grp_id &&
          data.grp_id.map((gp_id) => {
            let data1 = group.find((gp) => gp_id === gp?.grp_id)
            return data1
          })
        data.gm_group = tempData
      })

      setGroupList(groupMember)
      fetchSingleGroupDetails(groupMember[0].gm_group[0]?.grp_id)
      setSelectedGroup(groupMember[0].gm_group[0]?.grp_id)
      if (groupMember[0].gm_group.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setIsLoading(false)
      setIsSubmitLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
      setIsSubmitLoading(false)
    }
  }

  const fetchGroupMemberBySchoolAndClass = async () => {
    try {
      setIsLoading(true)
      let response = await getGroupsBySchoolAndClass(
        fullUserInfo?.st_level,
        userInfo?.id,
      )
      let { group } = response.data
      setRelatedGroupList(group)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const fetchStudentsByClassAndDivAPI = async () => {
    try {
      setIsLoading(true)
      let response = await getStudentsByClassAndSchoolAPI(
        fullUserInfo?.st_class,
        fullUserInfo?.st_schoolId,
      )
      let { student } = response.data
      setClassMatesList(student)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const fetchSingleGroupDetails = async (id) => {
    try {
      setIsLoading(true)
      setSingleGroupDetails({})
      let response = await getSingleGroupDetails(id)
      let { group } = response.data
      setSingleGroupDetails(group)
      setTemp(temp + 1)
      setIsLoading(false)
      setNoRecords(false)
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  const createGroup = async (values) => {
    try {
      setIsLoading(true)
      setIsSubmitLoading(true)
      let body = {
        st_id: userInfo?.id,
        grp_name: values?.grp_name,
        grp_desc: values?.grp_desc ? values?.grp_desc : '',
        sc_id: fullUserInfo?.st_schoolId,
        grp_class: fullUserInfo?.st_class,
      }
      let response = await createGroupAPI(body)
      if (response?.status) {
        let grp_id = response?.data?.group?.grp_id
        toggleModal()
        fetchGroupMemberByStudent()
        fetchGroupMemberBySchoolAndClass()
        fetchSingleGroupDetails(grp_id)
        setSelectedGroup(grp_id)
      }
      setIsLoading(false)
      setIsSubmitLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
      setIsSubmitLoading(false)
    }
  }

  const joinGroup = async (grp_id) => {
    try {
      setIsLoading(true)
      let body = {
        st_id: userInfo?.id,
        grp_id: grp_id,
      }
      let response = await joinGroupAPI(body)
      if (response?.status) {
        fetchGroupMemberByStudent()
        setTemp(temp + 1)
        fetchGroupMemberBySchoolAndClass()
        fetchSingleGroupDetails(grp_id)
        setSelectedGroup(grp_id)
      }
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleBlogModal = () => {
    setBlogModal(!blogModal)
  }

  const createBlog = async (values) => {
    try {
      setIsSubmitLoading(true)
      let body = {
        bl_title: values?.bl_title,
        bl_desc: values?.bl_desc,
        bl_image: blogForm.bl_image.file,
        bl_creation_type: 'Student',
        bl_creatorId: userInfo?.id,
        grp_id: singleGroupDetails?.grp_id,
      }
      let response = await createBlogAPI(body)
      if (response?.status) {
        toggleBlogModal()
        fetchSingleGroupDetails(singleGroupDetails?.grp_id)
        toast.success('Blog added sucessfully.')
        setBlogForm({
          bl_title: '',
          bl_desc: '',
          bl_image: { fileName: '', file: {} },
        })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      setError(error)
      setIsSubmitLoading(false)
      // setIsLoading(false)
    }
  }

  useEffect(() => {}, [temp])

  const handleExitDeleteGroup = async (grp_id, st_id, grpExitType) => {
    // 1 = Exit Group, 2 = Delete Group
    const value = await swal(
      `Are you sure want to ${grpExitType === 1 ? 'Exit' : 'Delete'} Group`,
      {
        buttons: {
          delete: grpExitType === 1 ? 'Exit' : 'Delete',
          cancel: 'Cancel',
        },
      },
    )
    if (value === 'delete') {
      let response
      setIsLoading(true)
      if (grpExitType === 1) {
        response = await exitGroup(grp_id, st_id)
      } else if (grpExitType === 2) {
        response = await deleteGroupByAdmin(grp_id, st_id)
      }
      if (response?.status) {
        fetchGroupMemberBySchoolAndClass()
        fetchGroupMemberByStudent()
        setSingleGroupDetails({})
        setSelectedGroup(null)
      }
      setIsLoading(false)
    }
  }

  const isParticipantInGroup = () => {
    return singleGroupDetails?.grp_participants.some(
      (data) => data === userInfo?.id,
    )
  }

  const likeBlog = async (id, grp_id, index, likeCount) => {
    try {
      const isLoggedIn = getAuthToken() ? true : false
      if (!isLoggedIn) {
        loginAlert()
      } else {
        singleGroupDetails.grp_blogs[index].isLiked = true
        singleGroupDetails.grp_blogs[index].bl_likeCount = likeCount + 1
        setTemp(!temp)
        await likeBlogAPI(id)
      }
    } catch (error) {
      setError(error)
    }
  }

  const loginAlert = async () => {
    const value = await swal('Please do login into your account', {
      buttons: {
        defeat: 'Log in',
        cancel: 'Cancel',
      },
    })
    switch (value) {
      case 'defeat':
        navigate('/auth/signin', {
          state: {
            url: pathName,
          },
        })
        break
      default:
    }
  }

  const hideShowComments = async (index, bl_id) => {
    const isLoggedIn = getAuthToken() ? true : false
    if (!isLoggedIn) {
      loginAlert()
    } else {
      let tempBlog = singleGroupDetails
      tempBlog.grp_blogs[index].showComments =
        !tempBlog.grp_blogs[index].showComments
      if (tempBlog.grp_blogs[index].isFetchComments) {
      } else {
        setIsCommentLoading(true)
        let response = await getCommentsByBlogId(bl_id)
        if (response?.status) {
          let { comment } = response.data
          tempBlog.grp_blogs[index].bl_comment = comment
          tempBlog.grp_blogs[index].isFetchComments = true

          if (comment.length === 0) {
            tempBlog.grp_blogs[index].showNoRecordsMsg = true
            setNoRecordsForComment(true)
          } else {
            tempBlog.grp_blogs[index].showNoRecordsMsg = false
            setNoRecordsForComment(false)
          }
        } else {
          console.log('Error while fetching comments')
        }
        setIsCommentLoading(false)
      }
      setSingleGroupDetails(tempBlog)
      setTemp(!temp)
    }
  }

  const addComment = async (bl_id, index) => {
    try {
      let userType = ''
      if (
        userInfo?.userType === 'Student' ||
        userInfo?.userType === 'Premium'
      ) {
        userType = 'Student'
      } else if (
        userInfo?.userType === 'Teacher' ||
        userInfo?.userType === 'Freelance'
      ) {
        userType = 'Teacher'
      }
      if (comment.length === 0) {
        return setShowError(true)
      } else {
        setShowError(false)
        setIsSubmitLoading(true)
        let body = {
          cmn_userId: userInfo?.id,
          cmn_userType: userType,
          bl_id,
          cmn_comment: comment,
          cmn_date: new Date(),
        }
        let response = await createComment(body)
        if (response.status) {
          toast.success('Thank you for your comment', { autoClose: 5000 })
          let tempBlog = singleGroupDetails
          tempBlog.grp_blogs[index].bl_comment = [
            ...tempBlog.grp_blogs[index].bl_comment,
            {
              cmn_comment: comment,
              cmn_userId: userInfo?.id,
              cmn_userType: userType,
              cmn_fullName: userInfo?.fullName,
              cmn_profilePic: userInfo?.profilePic,
              bl_id: bl_id,
              cmn_date: new Date(),
            },
          ]
          setSingleGroupDetails(tempBlog)
          setComment('')
        } else {
          console.log('Something went wrong')
        }

        setIsSubmitLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setIsSubmitLoading(false)
      setError(error)
    }
  }

  return (
    <>
      <ToastContainer position="top-right" />
      <section className="py-5">
        <Modal
          isOpen={blogModal}
          size="md"
          role="dialog"
          autoFocus={true}
          centered={true}
          className="tutorModal"
          tabIndex="-1"
          toggle={toggleBlogModal}
        >
          <ModalHeader toggle={toggleBlogModal}>Create Group Blog</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                bl_title: '',
                bl_desc: '',
                bl_image: '',
              }}
              validationSchema={Yup.object({
                bl_title: Yup.string()
                  .required('Please Enter Blog Title')
                  .nullable(),
                bl_desc: Yup.string()
                  .required('Please Enter Description')
                  .nullable(),
                bl_image: Yup.mixed()
                  .optional()
                  .test('fileFormat', 'Unsupported Format', (value) => {
                    if (!blogForm?.['bl_image']?.file?.type)
                      return blogForm['bl_image'].fileName === '' || false

                    return ['image/jpg', 'image/png', 'image/jpeg'].includes(
                      blogForm?.['bl_image'].file.type,
                    )
                  })
                  .nullable(),
              })}
              onSubmit={async (values) => {
                createBlog(values)
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
              }) => (
                <Form
                  className="mt-4 pt-2"
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                    return false
                  }}
                >
                  <div className="input--group">
                    <Field
                      className={`form-control ${
                        errors.bl_title && touched.bl_title ? 'form-err' : ''
                      }`}
                      name="bl_title"
                      placeholder="Enter Blog Title"
                    />
                    {errors.bl_title && touched.bl_title ? (
                      <div className="form-err-msg">{errors.bl_title}</div>
                    ) : null}
                  </div>
                  <div className="input--group">
                    <Field
                      className={`form-control ${
                        errors.bl_desc && touched.bl_desc ? 'form-err' : ''
                      }`}
                      component="textarea"
                      rows="4"
                      name="bl_desc"
                      type="text"
                      placeholder="Enter Description"
                    />
                    {errors.bl_desc && touched.bl_desc ? (
                      <div className="form-err-msg">{errors.bl_desc}</div>
                    ) : null}
                  </div>
                  <div className="input--group">
                    <Input
                      name="bl_image"
                      type="file"
                      accept=".png, .jpg"
                      placeholder="Select Book Pdf"
                      onChange={(e) => {
                        let tempForm = blogForm
                        tempForm['bl_image']['fileName'] = e.target.value
                        tempForm['bl_image']['file'] = e.target.files[0]
                        setBlogForm(tempForm)
                      }}
                      invalid={!!touched.bl_image && !!errors.bl_image}
                      defaultValue={blogForm.bl_image?.fileName}
                    />
                    {errors.bl_image && touched.bl_image ? (
                      <div className="form-err-msg">{errors.bl_image}</div>
                    ) : null}
                  </div>
                  <button
                    disabled={isSubmitLoading}
                    className="cmn--btn  w-100 form-control"
                    type="submit"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                      <span>Submit</span>
                    </div>
                  </button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modal}
          size="md"
          role="dialog"
          autoFocus={true}
          centered={true}
          className="tutorModal"
          tabIndex="-1"
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}>Create Group</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                grp_name: '',
              }}
              validationSchema={Yup.object({
                grp_name: Yup.string()
                  .required('Please Enter Group Name')
                  .nullable(),
              })}
              onSubmit={async (values) => {
                createGroup(values)
              }}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
              }) => (
                <Form
                  className="mt-4 pt-2"
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(e)
                    return false
                  }}
                >
                  <div className="input--group">
                    <Field
                      className={`form-control ${
                        errors.grp_name && touched.grp_name ? 'form-err' : ''
                      }`}
                      name="grp_name"
                      placeholder="Enter Group Name"
                    />
                    {errors.grp_name && touched.grp_name ? (
                      <div className="form-err-msg">{errors.grp_name}</div>
                    ) : null}
                  </div>

                  <div className="input--group">
                    <Field
                      className={`form-control textareaDesc ${
                        errors.grp_desc && touched.grp_desc ? 'form-err' : ''
                      }`}
                      component="textarea"
                      rows="2"
                      maxlength="120"
                      name="grp_desc"
                      type="text"
                      placeholder="Enter Group Description"
                    />
                    {errors.grp_desc && touched.grp_desc ? (
                      <div className="form-err-msg">{errors.grp_desc}</div>
                    ) : null}
                  </div>

                  <button
                    disabled={isSubmitLoading}
                    className="cmn--btn  w-100 form-control"
                    type="submit"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {isSubmitLoading && <ButtonLoader></ButtonLoader>}

                      <span>Submit</span>
                    </div>
                  </button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        <div className="container">
          <div className="books-section row g-3">
            <div className="books-sidebar col-lg-3">
              <div className="books-sidebar-inner">
                <div className="books-sidebar-top books-sidebar-widget">
                  <h5 className="m-0 fw-bold">My Groups</h5>
                  <button
                    className="cmn--btn"
                    type="button"
                    onClick={toggleModal}
                  >
                    New
                  </button>
                </div>
                <div className="books-sidebar-widget grp-list">
                  {groupList &&
                    groupList.length > 0 &&
                    groupList.map((gm) => {
                      return gm.gm_group && gm.gm_group?.length ? (
                        <>
                          {gm.gm_group.map((gp, i) => {
                            return (
                              <>
                                <div className="d-flex justify-content-between mb-2">
                                  <p
                                    className={`subtitle grp-title ${
                                      selectedGroup === gp?.grp_id
                                        ? 'selected-group'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      fetchSingleGroupDetails(gp?.grp_id)
                                      setSelectedGroup(gp?.grp_id)
                                    }}
                                  >
                                    {gp?.grp_name}
                                  </p>
                                  {gm?.st_id === gp?.st_id ? (
                                    <span className="">
                                      <FaUser />
                                    </span>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                              </>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          <p className="subtitle">No system activity found.</p>
                        </>
                      )
                    })}
                </div>
              </div>

              <div className="books-sidebar-inner mt-4">
                <div className="books-sidebar-top books-sidebar-widget">
                  <h5 className="m-0 fw-bold">Groups</h5>
                </div>
                <div className="books-sidebar-widget">
                  {relatedGroupList && relatedGroupList.length > 0 ? (
                    relatedGroupList.map((gp) => {
                      return (
                        <>
                          <div
                            className="d-flex justify-content-between mb-4 single-related-group cursor-pointer"
                            onClick={() => {
                              fetchSingleGroupDetails(gp?.grp_id)
                              setSelectedGroup(gp?.grp_id)
                            }}
                          >
                            <p
                              className={`subtitle ${
                                selectedGroup === gp?.grp_id
                                  ? 'selected-group'
                                  : ''
                              }`}
                            >
                              {gp?.grp_name}
                            </p>
                            <span className="icon">
                              <MdOutlineGroupAdd className="join-group" />
                            </span>
                          </div>
                        </>
                      )
                    })
                  ) : (
                    <p className="subtitle">No system activity found.</p>
                  )}
                </div>
              </div>
            </div>

            <>
              <div className="col-lg-9 blog-main-section">
                <div style={{ minHeight: '300px' }}>
                  {isLoading ? (
                    <div
                      style={
                        isLoading
                          ? {
                              position: 'relative',
                              opacity: '0.8',
                              minHeight: '300px',
                            }
                          : {}
                      }
                    >
                      <SubmitLoader />
                    </div>
                  ) : (
                    <>
                      {noRecords && (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: '300px' }}
                        >
                          <h1>No system activity found.</h1>
                        </div>
                      )}
                      {singleGroupDetails &&
                        Object.keys(singleGroupDetails).length > 0 && (
                          <>
                            <div className="blog-box">
                              <div className="d-flex justify-content-between mb-3 align-items-center">
                                <div>
                                  <h4 className="title mt-0">
                                    {singleGroupDetails?.grp_name}
                                  </h4>
                                  {singleGroupDetails?.grp_desc && (
                                    <p className="title mt-2 grp_desc">
                                      {singleGroupDetails?.grp_desc}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  {isParticipantInGroup() ? (
                                    <>
                                      {singleGroupDetails?.st_id ==
                                      userInfo?.id ? (
                                        <p
                                          className="d-inline me-3 mt-0 exit-group cursor-pointer"
                                          onClick={() =>
                                            handleExitDeleteGroup(
                                              singleGroupDetails?.grp_id,
                                              userInfo?.id,
                                              2,
                                            )
                                          }
                                        >
                                          Delete Group
                                        </p>
                                      ) : (
                                        <p
                                          className="d-inline me-3 mt-0 exit-group cursor-pointer"
                                          onClick={() =>
                                            handleExitDeleteGroup(
                                              singleGroupDetails?.grp_id,
                                              userInfo?.id,
                                              1,
                                            )
                                          }
                                        >
                                          Exit Group
                                        </p>
                                      )}
                                      <button
                                        className="checkResultBtn"
                                        onClick={toggleBlogModal}
                                      >
                                        Create Group Blog
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        className="d-inline me-3 mt-0 exit-group cursor-pointer"
                                        onClick={() => {
                                          joinGroup(singleGroupDetails?.grp_id)
                                        }}
                                      >
                                        Join Group
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                              <hr className="" />
                              {singleGroupDetails?.grp_participants_details && (
                                <div className="student-carasol">
                                  <OwlCarousel
                                    items={
                                      singleGroupDetails
                                        ?.grp_participants_details.length < 4
                                        ? singleGroupDetails
                                            ?.grp_participants_details.length
                                        : 4
                                    }
                                    className="owl-theme"
                                    loop
                                    margin={10}
                                    center={false}
                                    dots={false}
                                  >
                                    {Object.keys(singleGroupDetails).length >
                                      0 &&
                                      singleGroupDetails?.grp_participants_details &&
                                      singleGroupDetails?.grp_participants_details.map(
                                        (data) => {
                                          return (
                                            <>
                                              <div class="item">
                                                <div class="text-center">
                                                  <img
                                                    className="student-img"
                                                    src={
                                                      data?.st_profilePic
                                                        ? `${IMAGE_URL}/${data?.st_profilePic}`
                                                        : default_icon
                                                    }
                                                    alt=""
                                                    srcset=""
                                                  />
                                                  <p className="mt-1 pb-2">
                                                    {data?.st_fullName}
                                                  </p>
                                                </div>
                                              </div>
                                            </>
                                          )
                                        },
                                      )}
                                  </OwlCarousel>
                                </div>
                              )}
                            </div>
                            {Object.keys(singleGroupDetails).length > 0 &&
                              singleGroupDetails?.grp_blogs &&
                              singleGroupDetails?.grp_blogs.length > 0 &&
                              singleGroupDetails?.grp_blogs.map((data, i) => {
                                return (
                                  <>
                                    <div className="blog-box single-blog mt-3">
                                      <div className="row g-2 g-sm-4 mb-4">
                                        <div className="col-12">
                                          {data?.bl_image &&
                                            data?.bl_image != null && (
                                              <img
                                                className="w-100 blog-img"
                                                src={`${IMAGE_URL}/${data?.bl_image}`}
                                                alt=""
                                              />
                                            )}
                                        </div>
                                      </div>
                                      <h6 className="mb-3 blog-title">
                                        {data?.bl_title}
                                      </h6>
                                      <p className="mt-0 blog-desc">
                                        {data?.bl_desc}
                                      </p>
                                      <div className="row g-2 g-sm-4 mb-4">
                                        <div className="col">
                                          <div className="d-flex teacher-list align-items-center mb-3 justify-content-center">
                                            <img
                                              className="me-3"
                                              src={
                                                data?.bl_creatorPic
                                                  ? `${IMAGE_URL}/${data?.bl_creatorPic}`
                                                  : default_icon
                                              }
                                              alt=""
                                              srcset=""
                                            />
                                            <p className="subtitle mb-3">
                                              {data?.bl_creatorName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <span className="d-flex align-items-center icon icon-color justify-content-center">
                                            <BsCalendarWeek />{' '}
                                            <p className="m-0 ms-2">
                                              {moment(
                                                data?.bl_createdAt,
                                              ).format('DD MMM YYYY')}
                                            </p>
                                          </span>
                                        </div>
                                        <div className="col">
                                          <span className="d-flex align-items-center icon justify-content-center">
                                            <SlLike
                                              style={{
                                                color: data?.isLiked
                                                  ? '#00bee3'
                                                  : '',
                                              }}
                                              className="cursor-pointer"
                                              onClick={() => {
                                                if (!data?.isLiked) {
                                                  likeBlog(
                                                    data?.bl_id,
                                                    singleGroupDetails?.grp_id,
                                                    i,
                                                    data?.bl_likeCount,
                                                  )
                                                }
                                              }}
                                            />{' '}
                                            <p className="m-0 ms-2">
                                              {data?.bl_likeCount > 0
                                                ? data?.bl_likeCount + ' '
                                                : ' '}
                                              Like
                                            </p>
                                          </span>
                                        </div>
                                        <div className="col">
                                          <span
                                            className="d-flex align-items-center icon cursor-pointer"
                                            onClick={() =>
                                              hideShowComments(i, data?.bl_id)
                                            }
                                          >
                                            <BiCommentDetail />{' '}
                                            <p className="m-0 ms-2">Comment</p>
                                          </span>
                                        </div>
                                      </div>
                                      {data?.showComments && (
                                        <div className="comment-section">
                                          <div style={{ minHeight: '100px' }}>
                                            {isCommentLoading ? (
                                              <div
                                                style={
                                                  isCommentLoading
                                                    ? {
                                                        position: 'relative',
                                                        opacity: '0.8',
                                                        minHeight: '100px',
                                                      }
                                                    : {}
                                                }
                                              >
                                                <SubmitLoader />
                                              </div>
                                            ) : (
                                              <>
                                                <div className="row comment-section">
                                                  <div className="col-12">
                                                    <div className="comment-input position-relative">
                                                      <input
                                                        onChange={(e) => {
                                                          setComment(
                                                            e.target.value,
                                                          )
                                                          if (
                                                            e.target.value
                                                              .length > 0
                                                          ) {
                                                            setShowError(false)
                                                          } else {
                                                            setShowError(true)
                                                          }
                                                        }}
                                                        value={comment}
                                                        className={`form-control`}
                                                        name="bookName"
                                                        placeholder={`Add Comment`}
                                                      />
                                                      <button
                                                        disabled={
                                                          isSubmitLoading
                                                        }
                                                        className="position-absolute comment-btn"
                                                        onClick={() =>
                                                          addComment(
                                                            data.bl_id,
                                                            i,
                                                          )
                                                        }
                                                      >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                          {isSubmitLoading && (
                                                            <ButtonLoader
                                                              height="1.2rem"
                                                              width="1.2rem"
                                                            ></ButtonLoader>
                                                          )}

                                                          <span>Post</span>
                                                        </div>
                                                      </button>
                                                    </div>
                                                  </div>
                                                  {showError && (
                                                    <p className="text-danger mt-2">
                                                      Please add comment
                                                    </p>
                                                  )}
                                                </div>
                                                <div className="comments-list">
                                                  <ul className="comments-list-items">
                                                    {data?.bl_comment &&
                                                    data?.bl_comment.length >
                                                      0 ? (
                                                      data?.bl_comment.map(
                                                        (data) => {
                                                          return (
                                                            <li>
                                                              <img
                                                                src={`${IMAGE_URL}/${data?.cmn_profilePic}`}
                                                                alt="profile"
                                                              />
                                                              <div className="cont">
                                                                <div className="top">
                                                                  <div>
                                                                    <h5 className="name">
                                                                      {
                                                                        data?.cmn_fullName
                                                                      }
                                                                    </h5>
                                                                    <span className="date">
                                                                      {moment(
                                                                        data?.cmn_date,
                                                                      ).format(
                                                                        'DD MMM YYYY',
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <p>
                                                                  {
                                                                    data?.cmn_comment
                                                                  }
                                                                </p>
                                                              </div>
                                                            </li>
                                                          )
                                                        },
                                                      )
                                                    ) : (
                                                      <>
                                                        {data?.showNoRecordsMsg && (
                                                          <div
                                                            className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                              minHeight:
                                                                '100px',
                                                            }}
                                                          >
                                                            <h6>
                                                              No comments yet
                                                            </h6>
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </ul>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <hr className="margin-y-30" />
                                    </div>
                                  </>
                                )
                              })}
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  )
}
export default Group
