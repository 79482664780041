import '../privacy/Privacy.scss'

const Faq = () => {
  return (
    <section className="terms-section py-5">
      <div className="container">
        {/* <h6>Welcome to LMS!</h6> */}
        <ol>
          <li>
            <h6 className="subtitle">What is LMS?</h6>
            <p>
              Ans: Teaching and Learning Management System (LMS) is a mobile and
              web-based educational tool aimed at digitalizing the educational
              system. Due to the evolution of education from the traditional
              teaching and learning system to the smart classroom system, LMS is
              bridging educational gaps in the educational system through
              E-learning and technology for teachers and students.
            </p>
          </li>
          <li>
            <h6 className="subtitle">How do I sign-up on the LMS platform?</h6>
            <p>
              Ans: The MoE as a stakeholder provides user accounts for all to
              log in with or visit lmsgh.net.
            </p>
          </li>
          <li>
            <h6>Does LMS require payment?</h6>
            <p>Ans: No payment is required for the LMS platform.</p>
          </li>
          <li>
            <h6 className="subtitle">Is LMS available in other countries?</h6>
            <p>Ans: The LMS is designed to suit every country's host.</p>
          </li>
          <li>
            <h6 className="subtitle">Can I use LMS app on my laptop?</h6>
            <p>Ans: LMS is also available on the web and for tablet users.</p>
          </li>
          <li>
            <h6 className="subtitle">
              What is the connect feature on the LMS for?
            </h6>
            <p>
              Ans: Welcome to our connecting feature, a vibrant digital
              environment where you can share your ideas, experiences, and views
              with our community. It's similar to a blog but better Express
              yourself effectively through brief, insightful posts and engage in
              meaningful discussion with others, Connect, inspiren and keep
              informed via this dynamic platform created specifically for you.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              How do I create a “new connect” on LMS?{' '}
            </h6>
            <p>
              Ans: You go to create after clicking connect on the menu bar, and
              this allows you to build a “new connect” where you may discuss
              ideas and opinions with others.
            </p>
          </li>
          <li>
            <h6 className="subtitle">What subjects does the LMS offer?</h6>
            <p>
              Ans: LMS provides a wide range of topics/subjects for Primary,
              Junior, and Senior Secondary school students. These subjects are
              Mathematics, ICT, English Language, RME, Integrated Science,
              Social Studies, and Creative Arts.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Can I get access to past questions on the LMS?{' '}
            </h6>
            <p>
              Ans: LMS's large database of past question papers and solutions
              makes it a perfect product for assisting a learner in preparing
              for key tests or examinations.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Can I solve past questions on LMS? </h6>
            <p>Ans: No, past questions cannot be solved on the LMS.</p>
          </li>
          <li>
            <h6 className="subtitle">
              How do I use the self-assessment on the LMS?{' '}
            </h6>
            <p>
              Ans: Take advantage of the self-assessment on the LMS to
              objectively evaluate yourself and gain valuable insights for
              personal growth in education.
            </p>
          </li>
          <li>
            <h6 className="subtitle">Do assignments have a time duration? </h6>
            <p>
              Ans: Yes, the LMS assignments are subject to a time limitation.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Can books from the library section be shared with others?
            </h6>
            <p>
              Ans: Books and videos can be shared by simply copying the URL or
              clicking on the share button.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              How do I access my downloaded videos and books from the library?
            </h6>
            <p>Ans: There is no option to download on the LMS platform.</p>
          </li>
          <li>
            <h6 className="subtitle">Do I need a smartphone to use LMS? </h6>
            <p>
              Ans: Yes, any smartphone with Android, iOS or Windows features can
              be used to access LMS GHANA.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Does using the LMS app mean I have to use my data?
            </h6>
            <p>
              Ans: Yes, it requires data nonetheless there is an offline option
              that users can use to access downloaded or shared documents.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Can I have access to my downloaded videos and PDFs when offline?{' '}
            </h6>
            <p>
              Ans: Yes, you can access your downloaded videos and PDFs offline.
              Simply download the files to your device when you have an internet
              connection. Once downloaded, you can open and view them anytime,
              even without an internet connection. This provides convenient
              access to your educational materials, allowing you to study or
              review them on the go, without the need for an internet
              connection. Enjoy seamless learning wherever you are.
            </p>
          </li>
          <li>
            <h6 className="subtitle">
              Can I use LMS on a device aside from the tablet?
            </h6>
            <p>
              Ans: You absolutely can. You can use the app to learn on a laptop
              or smartphone.
            </p>
          </li>
          <li>
            <h6 className="subtitle">How can I download the LMS app?</h6>
            <p>Ans: Download the LMS app from Play Store or App Store.</p>
          </li>
          <li>
            <h6 className="subtitle">
              Can I change my phone number or email?{' '}
            </h6>
            <p>
              Ans: Yes, to update your phone number, first sign up for the LMS
              app with the new number, then send an email to info@lmsgh.net or
              call 0800790555 requesting a phone number change.
            </p>
          </li>
        </ol>
      </div>
    </section>
  )
}

export default Faq
