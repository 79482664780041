import SubmitLoader from 'components/Common/SubmitLoader'
import { getFullUserInfo } from 'helpers/authHelper'
import { useEffect, useState } from 'react'
import noMessages from '../../assets/images/default/no-message.jpg'
import DashboardSidebar from '../../components/DashboardSidebar'
import { getMessages } from '../../helpers/backendHelpers/sendMessage'
import './message.scss'
const Messages = () => {
  const [messageList, setMessageList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [noRecords, setNoRecords] = useState(false)
  const fullUserInfo = getFullUserInfo()

  useEffect(() => {
    getMessageData()
  }, [])

  const getMessageData = async () => {
    const sm_class = fullUserInfo?.st_classRoomId
      ? fullUserInfo?.st_classRoomId
      : ''
    const sm_student = fullUserInfo?.st_id ? fullUserInfo.st_id : ''
    const sc_id = fullUserInfo?.st_schoolId ? fullUserInfo?.st_schoolId : ''

    try {
      setIsLoading(true)
      let response = await getMessages(sm_student, sm_class, sc_id)
      let { sendMessage } = response.data
      setMessageList(sendMessage)

      if (sendMessage.length === 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setIsLoading(false)
    } catch (error) {
      setNoRecords(true)
      setError(error)
      setIsLoading(false)
    }
  }

  return (
    <div className="py-5">
      <div className="container">
        <div className="d-flex flex-wrap">
          <DashboardSidebar />
          <div className="dashboard-article">
            <div className="card cmn--card">
              <div className="card-header py-3">
                <h5 className="card-title">My Messages</h5>
              </div>
              <div style={{ minHeight: '300px' }}>
                {isLoading ? (
                  <div
                    style={
                      isLoading
                        ? {
                            position: 'relative',
                            opacity: '0.8',
                            minHeight: '300px',
                          }
                        : {}
                    }
                  >
                    <SubmitLoader />
                  </div>
                ) : (
                  <>
                    {noRecords && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <img src={noMessages} alt="" />
                        <h4>No system activity found.</h4>
                      </div>
                    )}
                    <div className="card-body">
                      {messageList &&
                        messageList.length > 0 &&
                        messageList.map((item, i) => {
                          return (
                            <>
                              <li className="other" key={i}>
                                <div className="msg">
                                  <strong>Teacher Name</strong>
                                  <div className="message">{item.sm_msg}</div>
                                  <p className="text-end">
                                    {item.sm_createdAt}
                                  </p>
                                </div>
                                <div className="image ">
                                  <img
                                    src="https://dashapi.katon.app/uploads/kt_teacher/tc_profilePic/1670500770680.jpg"
                                    className="rounded-circle"
                                    alt="profile"
                                  />
                                </div>
                              </li>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages
